import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import constants from "../../constants/constants";
import TextField from '@material-ui/core/TextField';
import { useFormik } from "formik";
import UserValidator from "../../helpers/validators/UserValidator";
import { getErrorField } from "../../helpers/helper";
import * as projectService from "../../services/service/projectService";
import * as userProjectService from "../../services/management/userProjectService";
import * as userService from "../../services/management/userService";
import * as alert from "../../redux/alertSnackBarRedux";
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { blue } from '@material-ui/core/colors';
import FormControl from '@material-ui/core/FormControl';
import { AirlineSeatReclineExtraOutlined, LocalConvenienceStoreOutlined } from '@material-ui/icons';


const BlueCheckbox = withStyles({
    root: {
        color: blue[400],
        '&$checked': {
            color: blue[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const initialState = {
    projectName: '',
    userID: '',
    projectID: 0,
    accessibleToUser: '',
}

const AssignProjectModal = (props) => {
    const { showAlert, show, hide, reloadTable, userToEditId } = props;
    const [userProject, setUserProject] = useState(initialState);
    const [projectList, setProjectList] = useState([]);
    const [clientId, setClientId] = useState(0);
    const [userProjectList, setUserProjectList] = useState([]);
    const [userProjects, setUserProjects] = useState([]);

    useEffect(() => {
        if (userToEditId && show) {
            projectService.getProjectByClientId(userToEditId).then(res => {
                setProjectList(res);
            });
            userProjectService.GetUserProjects(userToEditId).then(res => {
                setUserProjectList(res);
            });
        }
    }, [show]);

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: UserValidator,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            // saveUserProject(values, setSubmitting);
        }
    });

    const saveUserProject = (values) => {
        userProjectService.saveUserProject(values).then(res => {
            showAlert({ message: res.message, variant: 'success' });
        }).catch(ex => {
            console.log(ex);
        });
    }

    const hideUserProjectModal = () => {
        formik.resetForm();
        hide();
    }

    return (
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={hideUserProjectModal}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Assign Nspections
                </Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-12" style={{ maxHeight: 685, overflowY: 'scroll' }}>
                            {projectList.map((proj, i) => {
                                return (
                                    <div key={i}>
                                        <FormControl className="col-lg-12 d-flex justify-content-between flex-column pt-4">
                                            <FormControlLabel
                                                control={
                                                    <BlueCheckbox

                                                        checked={userProjectList.find(x => x.projectID === proj.id)?.accessibleToUser ?? false}
                                                        onChange={(e, val) => {
                                                            let tempArr = [...userProjectList];
                                                            let tempUserProject = tempArr.find(x => x.projectID === proj.id);
                                                            tempUserProject.accessibleToUser = val;

                                                            setUserProject(tempUserProject);
                                                            setUserProjectList(tempArr);
                                                            saveUserProject(tempUserProject);
                                                        }}
                                                    />
                                                }
                                                label={proj.projectName}
                                                labelPlacement="end"
                                            />
                                        </FormControl>
                                        
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button className="btn-light" onClick={hideUserProjectModal}>Cancel</Button>
                </Modal.Footer>
            </form>

        </Modal>
    );
}

export default connect(null, alert.actions)(AssignProjectModal);
import React, { useState, useEffect, useRef } from 'react';
import constants from '../../../../constants/constants';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import * as alert from "../../../../redux/alertSnackBarRedux";
import * as projectService from "../../../../services/service/projectService";
import * as projectPinService from "../../../../services/service/projectPinService";
import * as reportService from '../../../../services/settings/reportService';
import * as clientTierService from '../../../../services/management/clientTierService';
import { Carousel } from "react-responsive-carousel";
import ClipboardJS from 'clipboard';
import AddCommentModal from '../../../components/AddCommentModal';
import SVG from "react-inlinesvg";
import Annotation from 'react-image-annotation';
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import LinearProgress from '@material-ui/core/LinearProgress';
import { CircularProgress } from "@material-ui/core";
import {
  RectangleSelector
} from 'react-image-annotation/lib/selectors';
import { Tooltip } from "react-tooltip";
import { getErrorField, checkTierPlanAndRoleTier2, checkTierPlanAndRoleTier3 } from '../../../../helpers/helper';
import UpdateCustomFieldModal from '../../../components/UpdateCustomFieldModal';
import PropertyImprovementPlanReport from '../../../components/PropertyImprovementPlanReport';
import NewAnonymouseImageUploadModal from '../../../components/NewAnonymouseImageUploadModal';

const moment = require('moment');

const GenerateNewReportPage = (props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const keyFromToken = searchParams.get('key');
  const iFrameRef = useRef();
  const annotationRef = useRef(null);
  const useQueryString = () => new URLSearchParams(useLocation().search);
  const [isLoading, setIsLoading] = useState(true);
  const { auth, isReadOnly, token, showAlert } = props;
  const [annotation, setAnnotation] = useState({});
  const [annotations, setAnnotations] = useState([]);
  const [project, setProject] = useState({});
  const [isReportLoading, setIsReportLoading] = useState(true);
  const [tier, setTier] = useState('');
  const [projectType, setProjectType] = useState({});
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [isBusinessTier, setIsBusinessTier] = useState(false);
  const [btnPosition, setBtnPosition] = useState({
    left: 0,
    top: 0
  });
  const [toggleCode, setToggleCode] = useState(0);
  const [displayBtn, setDisplayBtn] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState('');
  const [addCommentModalVisible, setAddCommentModalVisible] = useState(false);
  const [showCustomFieldModalVisible, setShowCustomFieldModalVisible] = useState(false);
  const [projectPins, setProjectPins] = useState({});
  const [highlightedAnnotationIndex, setHighlightedAnnotationIndex] = useState(null);
  const [refreshList, setRefreshList] = useState(false);
  const [currentPin, setCurrentPin] = useState({});
  const [pinFromKey, setPinFromKey] = useState({});
  const [currentCustomField, setCurrentCustomField] = useState({});
  const [globalSdk, setGlobalSdk] = useState({});
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [fetchNewAnnotation, setFetchNewAnnotation] = useState(false);
  const [savedProjectPins, setSavedProjectPins] = useState([]);
  const [isBusinessOrProfessionalTier, setIsBusinessOrProfessionalTier] = useState(false);
  const [projectId, setProjectId] = useState(useQueryString().get('id'));
  const [keyFromURL, setKeyFromURL] = useState(keyFromToken);
  const [hasCompletedLoad, setHasCompletedLoad] = useState(false);
  const [addImageModalVisible, setAddImageModalVisible] = useState(false);

  useEffect(() => {
    if (token) {
      fetchProjectDataForSharable(token);
      fetchProjecPinByProjectIdSharable(token);
      return;
    }
    if (projectId) {
      fetchProjectData(projectId);
      fetchProjecPinByProjectId(projectId);
    }
  }, [projectId, refreshList, token]);

  const fetchTierForUser = (proj) => {
    let tierId = proj.client.clientTierId;
    let isTier2 = false;
    let isTier3 = false;
    if (tierId) {
      clientTierService.getClientTierById(tierId).then(res => {
        setTier(res);
        return { tier: res, proj: proj };
      }).then(tierAndProj => {
        isTier2 = checkTierPlanAndRoleTier2(tierAndProj.tier, auth.role);
        isTier3 = checkTierPlanAndRoleTier3(tierAndProj.tier, auth.role);
        if (isTier3) {
          setIsBusinessTier(isTier3);
        }
        if (isTier2) {
          setIsBusinessOrProfessionalTier(isTier2);
        }
      }).finally(() => {
        if (keyFromURL || token) {
          fetchProjectPinsForAProjectForReportSharable(token, isTier3);
        }
        else {
          fetchProjectPinsForAProject(proj, isTier3)
        }
      }
      );
    }
    else {
      if (auth.role === 'SuperAdmin') {
        fetchProjectPinsForAProject(proj, true); //superadmin
      }
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "auto"
    })
  }

  const handleGotopoint = (annotationCoordinates, imageIndexId) => {
    const { id } = annotationCoordinates.data;
    const index = project.projectModelImages.findIndex(x => x.id == imageIndexId);
    setCurrentImageIndex(imageIndexId);
    setHighlightedAnnotationIndex(id);
    setCarouselIndex(index);
    setAnnotation(annotationCoordinates);
    scrollToTop();
  };

  const navigateToPoint = (id) => {
    return globalSdk.Mattertag.navigateToTag(id, globalSdk.Mattertag.Transition.FLY);
  }

  const removeSelectedMatterTag = () => {
    setAnnotation({});
    setHighlightedAnnotationIndex(null)
  }

  const fetchProjectPinsForAProjectForReportSharable = (tokenVal, isBusinessOrProfessional) => {
    projectPinService.getProjectPinsByProjectIdForReportSharable(tokenVal).then(res => {
      if (Object.keys(res)) {
        registerMatterPort(res, isBusinessOrProfessional);
      }
    })
  }

  const fetchProjectPinsForAProject = (proj, isBusinessOrProfessional) => {
    projectPinService.getProjectPinsByProjectId(proj.id).then(res => {
      if (Object.keys(res)) {
        registerMatterPort(res, isBusinessOrProfessional);
      }
    })
  }

  const fetchProjectPinsForAProjectAndImageId = async (id, tokenVal) => {
    await projectPinService.getProjectPinsByProjectIdAndImageIdForShareable(tokenVal).then(res => {
      register2DProject(res, id);
    });
  }

  const register2DProject = (pins, id) => {
    let tempAnnotations = [];
    let pinsFiltered = [];
    if (!id) {
      pinsFiltered = pins.filter(x => x.projectModelImageId == currentImageIndex);
    }
    else {
      pinsFiltered = pins;
    }
    for (const pin of pinsFiltered) {
      tempAnnotations.push(JSON.parse(pin.annotationCoordinates));
    }
    setAnnotations(tempAnnotations);
    setSavedProjectPins(pins);
  }

  const registerMatterPort = (pins, isBusiness) => {
    console.log('matterport', iFrameRef.current);
    window.MP_SDK.connect(iFrameRef.current, constants.MATTERPORT_API_KEY, constants.MATTERPORT_VERSION).then((sdk) => {
      console.log('here is sdk', sdk);
      console.log('Matterport connected!');
      setGlobalSdk(sdk);

      addProjectPinsToMatterPort(sdk, pins, isBusiness);
    });
  }

  const renderAnnotations = (image, index) => {
    if (currentImageIndex !== index) return null;
    if (annotations.length > 0) {
      const renderSelector = ({ annotation }) => {
        if (!annotation.geometry) {
          return null;
        }
        return (
          <div
            style={{
              position: "absolute",
              left: `${annotation.geometry.x}%`,
              top: `${annotation.geometry.y}%`,
              width: `${annotation.geometry.width}%`,
              height: `${annotation.geometry.height}%`,
              backgroundColor: "rgba(255, 255, 255, 0.5)", // Make the annotation area lighter
              mixBlendMode: "screen", // Use mix-blend-mode to blend the selector with the overlay
              border: "1px solid black",
              display: "block"
            }}
          />
        );
      };
      return (
        <>
          <Annotation
            annotations={annotations}
            src={image}
            ref={annotationRef}
            type={RectangleSelector.TYPE}
            value={annotation}
            disableEditor
            onChange={onChangeHandler}
            style={{ height: '600px' }}
            renderContent={({ handleMouseEnter, annotation }) => {
              return (
                <div>
                  <div
                    id='my-tooltip'
                    data-tooltip-content={annotation.data.text}
                    style={{
                      position: "absolute",
                      left: `${annotation.geometry.x}%`,
                      top: `${annotation.geometry.y}%`,
                      width: `${annotation.geometry.width}%`,
                      height: `${annotation.geometry.height}%`,
                      backgroundColor: "rgba(255, 255, 255, 0.6)",
                      color: 'black',
                      border: "1px solid black",
                      display: "block"
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={() => {
                      if (highlightedAnnotationIndex) {
                        removeSelectedMatterTag()
                      }
                    }}
                  >
                    <span>Hover for details</span>
                  </div>
                  <Tooltip anchorId='my-tooltip' />
                </div>
              )
            }}
            renderSelector={renderSelector}
          />
        </>

      )
    }
    else {
      return (
        <Annotation
          annotations={annotations}
          src={image}
          ref={annotationRef}
          style={{ height: '600px' }}
          type={RectangleSelector.TYPE}
          value={annotation}
          disableEditor
          onChange={onChangeHandler}
        />
      )
    }

  };

  const onChangeHandler = annotation => {
    setAnnotation(annotation);
  };

  const hexToRgb = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: Math.round(parseInt(result[1], 16) / 255 * 10) / 10,
      g: Math.round(parseInt(result[2], 16) / 255 * 10) / 10,
      b: Math.round(parseInt(result[3], 16) / 255 * 10) / 10
    } : null;
  }

  const addProjectPinsToMatterPort = (sdk, pins, checkTier3) => {
    console.log('here are pins', pins);
    pins = pins.filter(x => x.matterPointCoordinates !== null);
    let allMatterTags = pins.map(pin => {
      let coordinate = JSON.parse(pin.matterPointCoordinates);
      if (coordinate) {
        return {
          label: pin.title,
          description: pin.description,
          ...(checkTier3 && pin.poiColorCode && {
            color: hexToRgb(pin.poiColorCode)
          }),
          anchorPosition: coordinate.position,
          stemVector: coordinate.normal,
          stemVisible: true
        }
      }
    })

    for (let i = 0; i < allMatterTags.length; i++) {
      sdk.Tag.add(allMatterTags[i]).then(function (mattertagIds) {
        pins[i].key = mattertagIds;
      }
      )
        .catch(ex => console.log("Exception is:", ex))
        .finally(() => {
          if ((i === allMatterTags.length - 1) && keyFromURL) {
            var dataIndex = pins.findIndex(x => x.id == keyFromURL);
            if (sdk && sdk.Mattertag && sdk.Mattertag.navigateToTag && sdk.Mattertag.Transition) {
              sdk.Mattertag.navigateToTag(pins[dataIndex].key[0], sdk.Mattertag.Transition.FLY);
            }
          }
        })

      if ((i === allMatterTags.length - 1) && keyFromURL) {
        setHasCompletedLoad(true);
      }
    }
    setSavedProjectPins(pins);
    setIsLoading(false);
  }

  useEffect(() => {
    if (hasCompletedLoad) {
      setToggleCode(1);
      scrollToTop();
    }
  }, [hasCompletedLoad]);

  const fetchProjectDataForSharable = async (token) => {
    await projectService.getProjectByIdForSharable(token).then(res => {
      setProject(res);
      if (res.projectModelImages.length > 0) {
        fetchProjectPinsForAProjectAndImageId(res.projectModelImages[0].id, token);
        setCurrentImageIndex(res.projectModelImages[0].id);
      }
      setProjectType(res.projectType);
      return res;
    }).then(proj => fetchTierForUser(proj)
    ).catch(err => console.log(err)
    ).finally(() => setIsReportLoading(false))
  }

  const fetchProjecPinByProjectIdSharable = (token) => {
    if (keyFromURL) {
      projectPinService.getProjectPinsByProjectIdForReportSharable(token).then(res => {
        setProjectPins(res);
        var pin = res.find(x => x.id == keyFromURL);
        setPinFromKey(pin);
      })
    }
    projectPinService.getProjectPinsByProjectIdForSharable(token).then(res => {
      setProjectPins(res);
    })
  }

  const fetchProjectData = async (id) => {
    await projectService.getProjectById(id).then(res => {
      setProject(res);
      setProjectType(res.projectType);
      return res;
    }).then(proj => fetchTierForUser(proj)
    ).catch(err => console.log(err)
    ).finally(() => setIsReportLoading(false))
  }

  const handleGoToOnclick = (projPin) => {
    if (projPin.annotationCoordinates) {
      setToggleCode(2);
      handleGotopoint(JSON.parse(projPin.annotationCoordinates), projPin.projectModelImageId);
    }
    else {
      var dataIndex = savedProjectPins.findIndex(x => x.id == projPin.id);
      navigateToPoint(savedProjectPins[dataIndex].key[0]);
      setToggleCode(1);
      scrollToTop();
    }
  }

  const fetchProjecPinByProjectId = (id) => {
    projectPinService.getProjectPinsByProjectIdForReport(id).then(res => {
      setProjectPins(res);
    }).catch(err => {
      showAlert({ message: err, variant: 'error' });
    }).then(() => {
      if (auth && auth.user && (auth.user.tier === 'Starter' || auth.user.tier === 'Individual')) {
        showAlert({ message: 'Please upgrade your subscription plan to higher tier to generate all project pins in the report.', variant: 'error', top: true });
      }
    })
  }

  const fetchHashLinkForReport = () => {
    if (isBusinessOrProfessionalTier) {
      reportService.getHashLinkForReport(projectId, auth.user.id).then(res => {
        // Create a temporary button element for ClipboardJS
        const tempButton = document.createElement('button');
        tempButton.setAttribute('data-clipboard-text', res);
        document.body.appendChild(tempButton);

        const clipboard = new ClipboardJS(tempButton);

        clipboard.on('success', () => {
          setTimeout(() => {
            showAlert({
              message: "Link has been generated and copied to clipboard.",
              variant: 'success',
            });
          }, 50);
          document.body.removeChild(tempButton);
          clipboard.destroy();
        });

        clipboard.on('error', () => {
          setTimeout(() => {
            showAlert({ message: "Failed to copy link to clipboard", variant: 'error' });
          }, 50);
          document.body.removeChild(tempButton);
          clipboard.destroy();
        });

        // Trigger a click event on the temporary button element
        tempButton.click();
      });
    } else {
      showAlert({ message: "This feature not available to you. Please upgrade to Enterprise or Business plan to access this feature.", variant: 'warning' });
    }
  };

  return (
    isReportLoading ? (<div className="col-xl-12 mt-lg-30">
      <LinearProgress />
    </div>)
      :
      (project && project.formJSON ?
        <>
          <div className="report-actions">
            {
              !keyFromURL ?
                (<Link
                  to={{
                    pathname: "/project/edit-project/" + projectId,
                    state: { projectId: projectId }
                  }}
                  className="bg-yellow"
                >
                  Back to Nspection
                </Link>)
                :
                (
                  <img src={`${toAbsoluteUrl("/media/logos/logo.png")}`} className="max-h-35px" />
                )
            }
          </div>
          <div className="report-title">
            <h1>
              {`${projectType.name} Report`}
            </h1>
            <h2>{project.projectName}</h2>
          </div>
          <div className="report-header">
            <div className="header-left">
              <h2>
                <span>Scanned on:</span>{moment(project.scanDate).format('MMMM Do, YYYY')}
              </h2>
              {
                project.completedDateTime ?
                  <h2>
                    <span>Completed on:</span>{moment(project.completedDateTime).format('MMMM Do, YYYY')}
                  </h2> : <></>
              }

              <div className="inspection-details">
                <h4>
                  <span>Nspection Description:</span> {project.notes ? project.notes : "No project notes available."}
                </h4>
              </div>

            </div>

            <div className="header-right">
              <div className="inspection-header">
                <h2>
                  Inspection Status:
                </h2>
                <span className={`inspection-badge lg ${project.completedDateTime ? "t-green" : "t-blue"}`}>
                  {project.completedDateTime ? 'Completed' : 'Active'}
                </span>
              </div>
            </div>
          </div>
          {project.metaDataAnswers && project.metaDataAnswers.length > 0 && (
            <div className="meta-data-answers">
              <h4><span><b>Nspection Properties</b></span></h4>
              <div className="meta-data-grid">
                {project.metaDataAnswers.map((answer, index) => (
                  <div key={index} className="meta-data-item">
                    <span className="meta-data-question">{answer?.projectTypeMetaDataField?.question}</span>
                    <span className="meta-data-answer">{answer?.answer || 'N/A'}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
          {
            keyFromURL ?
              (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div key={pinFromKey.id} className={`report-details`} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <div className="inspection-details-container">
                      <div className="inspection-header">
                        <h3>
                          {pinFromKey.title}
                        </h3>
                      </div>
                      <div className="inspection-details">
                        <h4>
                          <span>Reported By:</span> {pinFromKey.addedBy ?? pinFromKey.anonymousUser ?? 'N/A'}
                        </h4>
                        <h4>
                          <span>Reported On:</span> {moment(pinFromKey.updatedOn).format("Do, MMMM, YYYY")}
                        </h4>
                        <h4>
                          <span>Category:</span> {pinFromKey.pointCategory ?? 'N/A'}
                        </h4>

                        <h4>
                          <span>Resolved:</span> {pinFromKey.isResolved ? `Resolved` : `Not Resolved`}
                        </h4>
                        <h4>
                          <span>Approved:</span> {pinFromKey.isApproved ? `Approved` : `Not Approved`}
                        </h4>
                        <h4>
                          <span>Status:</span> {pinFromKey.projectTypePOIStatus ?? 'N/A'}
                        </h4>
                        {
                          pinFromKey.matterportResolutionLink &&
                          <h4>
                            <span>Resolution Link:</span> {pinFromKey.matterportResolutionLink}
                          </h4>
                        }
                        <h4>
                          {
                            pinFromKey.projectPinCustomFields?.length > 0 && !pinFromKey.projectPinCustomFields.every(x => !x.isViewable) &&
                            <>
                              <span>Custom Fields:</span>
                              <br />
                            </>
                          }
                          {pinFromKey.projectPinCustomFields?.length > 0 ? pinFromKey.projectPinCustomFields?.map((x, i) =>
                            <>
                              {
                                x.isViewable &&
                                <>
                                  <span key={i}>
                                    <b>{x.name}</b> : <span style={{ fontWeight: "normal" }}>{x.description ? x.description : '-'}</span>
                                  </span>
                                  <br />
                                </>
                              }
                            </>
                          ) : "-"}
                        </h4>
                      </div >
                    </div >
                  </div >

                  <div className="report-window">
                    {
                      project.modelLink &&
                      <div className="tab-content matterport-model-window" hidden={!(toggleCode === 1)} style={{ display: 'flex', justifyContent: 'end', alignItems: 'end', height: '480px', width: '853px', margin: '0 auto' }}>
                        <>
                          <a
                            style={{ left: btnPosition.left, top: btnPosition.top, display: displayBtn ? 'block' : 'none' }}
                            id="iframe-btn"
                          // type="button"
                          >
                            <span className="svg-icon svg-icon-3x svg-icon-danger m-0">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Map/Marker2.svg"
                                )}
                              />
                            </span>
                            {/* Add Marker */}
                          </a>
                          <iframe
                            ref={iFrameRef}
                            title='Matterport'
                            src={`${project.modelLink}&hr=0&play=1&title=0&qs=1&applicationKey=${constants.MATTERPORT_API_KEY}`}
                            id="showcase"
                            className="matterport-iframe"
                            frameBorder="0"
                            allowFullScreen
                            style={{ pointerEvents: selectedPoint || project.completedDateTime ? 'none' : 'auto', cursor: 'pointer' }}
                            allow="xr-spatial-tracking"
                          >
                          </iframe>
                        </>
                      </div>
                    }
                  </div>
                </div >

              )
              :
              <PropertyImprovementPlanReport
                formJson={JSON.parse(project.formJSON)}
                pins={projectPins}
                project={project}
                userId={auth.user.id}
              />}

        </>
        :
        <>
          {
            !isReadOnly ?
              (auth.role === "SuperAdmin" || auth.role === "Admin" || auth.role === "Manager") ?
                <div className="report-actions">
                  <Link
                    to={{
                      pathname: "/project/edit-project/" + projectId,
                      state: { projectId: projectId }
                    }}
                    className="bg-yellow"
                  >
                    Back to Nspection
                  </Link>

                  <button className="bg-blue" onClick={fetchHashLinkForReport}>
                    Generate Sharelink
                  </button>
                  <a
                    href
                    className="bg-blue print-report-btn"
                    onClick={() => {
                      window.open(constants.API_URL + `api/Report/GetInspectionPrintOut?projectId=${projectId}&token=${auth.authToken}`, '_blank')
                    }}
                    style={{
                      color: 'white',
                      textDecoration: 'none',
                      padding: '10px 20px',
                      borderRadius: '5px',
                      textTransform: 'uppercase'
                    }}
                  >
                    Print Report
                  </a>
                  <style>
                    {`
  .print-report-btn:hover {
    background-color: #0056b3;  // Darker blue on hover
    color: white;  // Ensure text stays white on hover
  }
`}
                  </style>
                </div>
                :
                <div className="report-actions">
                  <Link
                    to={{
                      pathname: "/project/edit-project/" + projectId,
                      state: { projectId: projectId }
                    }}
                    className="bg-yellow"
                  >
                    Back to Nspection
                  </Link>
                </div>
              : <></>
          }
          <div className="report-window">

            {
              token &&
              <>

                <img src={`${toAbsoluteUrl("/media/logos/logo.png")}`} className="max-h-35px" />
                <div className="card-body pt-0 pb-10 d-flex">
                  {

                    project.modelLink &&
                    <div className="tab-content matterport-model-window" hidden={!(toggleCode === 1)} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '480px', width: '853px', margin: '0 auto' }}>
                      <>
                        <a
                          style={{ left: btnPosition.left, top: btnPosition.top, display: displayBtn ? 'block' : 'none' }}
                          id="iframe-btn"
                        // type="button"
                        >
                          <span className="svg-icon svg-icon-3x svg-icon-danger m-0">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Map/Marker2.svg"
                              )}
                            />
                          </span>
                          {/* Add Marker */}
                        </a>
                        <iframe
                          ref={iFrameRef}
                          title='Matterport'
                          src={`${project.modelLink}&hr=0&play=1&title=0&qs=1&applicationKey=${constants.MATTERPORT_API_KEY}`}
                          id="showcase"
                          className="matterport-iframe"
                          frameBorder="0"
                          allowFullScreen
                          style={{ pointerEvents: selectedPoint || project.completedDateTime ? 'none' : 'auto', cursor: 'pointer' }}
                          allow="xr-spatial-tracking"
                        >
                        </iframe>
                      </>
                    </div>
                  }
                  {
                    token && project.projectModelImages && project.projectModelImages.length > 0 &&
                    <div className="tab-content matterport-model-window" hidden={!(toggleCode === 2)} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '480px', width: '853px', margin: '0 auto' }}>
                      <div className='image-container-carousel' style={{ width: '100%', height: '100%' }}>
                        <Carousel
                          showArrows
                          defaultActiveIndex={0}
                          selectedItem={carouselIndex}
                          onChange={(newActiveIndex) => {
                            const currentImageId = project.projectModelImages[newActiveIndex].id;
                            setCurrentImageIndex(currentImageId);
                            setCarouselIndex(newActiveIndex)
                            setFetchNewAnnotation(!fetchNewAnnotation)
                          }}
                        >
                          {project.projectModelImages.map((image, index) => (
                            <div key={index} style={{ position: 'relative', width: '100%', height: '100%' }}>
                              {renderAnnotations(
                                constants.API_URL + 'api/' + image.imagePath,
                                project.projectModelImages[index].id
                              )}
                            </div>
                          ))}
                        </Carousel>
                      </div>
                    </div>
                  }

                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <Button className="btn-success" onClick={() => {
                      setAddImageModalVisible(true);
                    }}>
                      Upload Photo
                    </Button>
                  </div>
                  <div >
                    <Button className="btn-success" onClick={() => {
                      setToggleCode(0);
                    }}>
                      Hide All
                    </Button>
                    &nbsp;
                    <Button className="btn-success" onClick={() => {
                      if (toggleCode === 0) {
                        setToggleCode(1);
                      }
                      if (toggleCode === 1) {
                        setToggleCode(2);
                      }
                      if (toggleCode === 2) {
                        setToggleCode(1);
                      }
                    }}>
                      {toggleCode === 1 ? `Show 2d` : `Show 3d`}
                    </Button>
                  </div>
                </div>



              </>

            }
            <div className="report-title">
              <h1>
                {`${projectType.name} Report`}
              </h1>
              <h2>{project.projectName}</h2>
            </div>


            <div className="report-header">
              <div className="header-left">
                <h2>
                  <span>Scanned on:</span>{moment(project.scanDate).format('MMMM Do, YYYY')}
                </h2>
                {
                  project.completedDateTime ?
                    <h2>
                      <span>Completed on:</span>{moment(project.completedDateTime).format('MMMM Do, YYYY')}
                    </h2> : <></>
                }

                <div className="inspection-details">
                  <h4>
                    <span>Nspection Description:</span> {project.notes ? project.notes : "No project notes available."}
                  </h4>
                </div>
              </div>

              <div className="header-right">
                <div className="inspection-header">
                  <h2>
                    Inspection Status:
                  </h2>
                  <span className={`inspection-badge lg ${project.completedDateTime ? "t-green" : "t-blue"}`}>
                    {project.completedDateTime ? 'Completed' : 'Active'}
                  </span>
                </div>
              </div>
            </div>
            {project.metaDataAnswers && project.metaDataAnswers.length > 0 && (
              <div className="meta-data-answers">
                <h4><span><b>Nspection Properties</b></span></h4>
                <div className="meta-data-grid">
                  {project.metaDataAnswers.map((answer, index) => (
                    <div key={index} className="meta-data-item">
                      <span className="meta-data-question">{answer?.projectTypeMetaDataField?.question}</span>
                      <span className="meta-data-answer">{answer?.answer || 'N/A'}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {
              projectPins && projectPins.projectPinListingDtos && projectPins.projectPinListingDtos.map((pin, index) =>
                <div key={pin.id} className={`report-details ${index === 0 ? "quarter-padding-top" : ""}`}>
                  <div className="inspection-details-container">
                    <div className="inspection-header">
                      <h3>
                        {pin.title}
                      </h3>
                      {
                        pin.projectPinCheckBoxes.map(x =>
                          <span className={`inspection-badge md t-red`}>
                            {x}
                          </span>
                        )
                      }
                    </div>
                    <div className="inspection-details">

                      <h4>
                        <span>Reported By:</span> {pin.addedBy ?? pin.anonymousUser ?? 'N/A'}
                      </h4>
                      <h4>
                        <span>Reported On:</span> {moment(pin.updatedOn).format("Do, MMMM, YYYY")}
                      </h4>
                      <h4>
                        <span>Category:</span> {pin.pointCategory ?? 'N/A'}
                      </h4>

                      <h4>
                        <span>Resolved:</span> {pin.isResolved ? `Resolved` : `Not Resolved`}
                      </h4>
                      <h4>
                        <span>Approved:</span> {pin.isApproved ? `Approved` : `Not Approved`}
                      </h4>
                      <h4>
                        <span>Status:</span> {pin.projectTypePOIStatus ?? 'N/A'}
                      </h4>
                      {
                        pin.matterportResolutionLink &&
                        <h4>
                          <span>Resolution Link:</span> {pin.matterportResolutionLink}
                        </h4>
                      }
                      <h4>
                        {
                          pin.projectPinCustomFields?.length > 0 && !pin.projectPinCustomFields.every(x => !x.isViewable) &&
                          <>
                            <span>Custom Fields:</span>
                            <br />
                          </>
                        }
                        {pin.projectPinCustomFields?.length > 0 ? pin.projectPinCustomFields?.map((x, i) =>
                          <>
                            {
                              x.isViewable &&
                              <>
                                <span key={i}>
                                  <b>{x.name}</b> : <span style={{ fontWeight: "normal" }}>{x.description ? x.description : '-'}</span>
                                </span>
                                <br />
                              </>
                            }
                          </>
                        ) : "-"}
                      </h4>
                      <h4>
                        <span>Comment:</span> {pin.comment ?? 'N/A'}
                      </h4>
                      <h4>
                        <span>Commented By:</span> {pin.commentedBy ? pin.commentedBy : pin.anonymousName}
                      </h4>
                      <h4>
                        <span>Notes:</span> {pin.description ?? 'N/A'}
                      </h4>
                      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        {
                          token && projectPins.allowCollaboration &&
                          <div className="comment-actions">
                            <Button className="btn-success" onClick={() => {
                              setAddCommentModalVisible(true);
                              setCurrentPin(pin);
                            }}>
                              Add Comment
                            </Button>
                          </div>
                        }
                        {
                          !token && (project && project.client && project.client.allowAnonymousCollaboration &&
                            <div className="comment-actions">
                              <Button className="btn-success" onClick={() => {
                                setAddCommentModalVisible(true);
                                setCurrentPin(pin);
                              }}>
                                Add Comment
                              </Button>
                            </div>
                          )
                        }
                        {
                          token && pin && pin.projectPinCustomFields.length > 0 &&
                          <div className="comment-actions">
                            <Button className="btn-success"
                              onClick={() => {
                                setShowCustomFieldModalVisible(true);
                                setCurrentPin(pin);
                              }}>
                              Edit Custom Fields
                            </Button>
                          </div>
                        }
                        {
                          pin.poiResolutionImagePath &&
                          <h4>
                            <span>Resolution Image:</span>     <div>
                              <img src={(constants.API_URL + 'api/' + pin.poiResolutionImagePath)} alt="Report" style={{ width: 300 }} />
                            </div>
                          </h4>
                        }
                        {
                          token &&
                          <div className="comment-actions">
                            <Button className="btn-success" onClick={() => handleGoToOnclick(pin)} disabled={isLoading || pin.anonymousUser}>
                              Go to Point
                            </Button>
                          </div>
                        }
                      </div>
                    </div>

                  </div>
                  {/* <div className="model-screenshot" style={{
                                backgroundImage: `url(${(constants.API_URL + 'api/' + pin.imageUrl)}`,
                                width: 500
                            }}>
                            </div> */}
                  <div>
                    <img src={(constants.API_URL + 'api/' + pin.imageUrl)} alt="Report" style={{ width: 550 }} />
                  </div>

                  {/* <div className="model-screenshot">
                                <img src={report.modelScreenshot} />
                            </div> */}

                </div>

              )
            }
            <AddCommentModal
              show={addCommentModalVisible}
              hide={() => {
                setAddCommentModalVisible(false);
                setCurrentPin({});
                setRefreshList(!refreshList);
              }}
              token={token}
              pin={currentPin}
            /> : <></>
            <UpdateCustomFieldModal
              show={showCustomFieldModalVisible}
              hide={() => {
                setShowCustomFieldModalVisible(false);
                setCurrentPin({});
                setRefreshList(!refreshList);
              }}
              token={token}
              pin={currentPin}
            /> : <></>
            <NewAnonymouseImageUploadModal
              show={addImageModalVisible}
              hide={() => {
                setAddImageModalVisible(false);
                setRefreshList(!refreshList);
              }}
              token={token}
            />

          </div>
        </>
      )
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth
})

export default connect(mapStateToProps, { ...alert.actions })(GenerateNewReportPage);
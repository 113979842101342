import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import UpdateProjectTypeModal from "../../../components/UpdateProjectTypeModal";
import NewProjectTypeModal from "../../../components/NewProjectTypeModal";
import * as alert from "../../../../redux/alertSnackBarRedux";
import * as confirmModal from "../../../../redux/confirmModalRedux";
import * as projectTypeService from '../../../../services/service/projectTypeService';
import * as clientTierService from '../../../../services/management/clientTierService';
import ManageProjectTypeModal from "../../../components/ManageProjectTypeModal";
import jwt_Decode from 'jwt-decode';


const ProjectTypeListingPage = (props) => {
    const { showAlert, showConfirmModal, hideConfirmModal, auth } = props;
    const history = useHistory();
    const [deleteProjectTypeModel, setDeleteProjectTypeModel] = useState({ id: 0, clientId: '', name: '', description: '', isDeleted: false })
    const [addProjectTypeModalVisible, setAddProjectTypeModalVisible] = useState(false);
    const [updateProjectTypeModalVisible, setUpdateProjectTypeModalVisible] = useState(false);
    const [manageProjectTypeModalVisible, setManageProjectTypeModalVisible] = useState(false);
    const [projectTypes, setProjectTypes] = useState([]);
    const [tier, setTier] = useState('');
    const [projectTypeData, setProjectTypeData] = useState([]);
    const [projectTypeId, setProjectTypeId] = useState([]);

    useEffect(() => {
        loadProjectTypes();
    }, [loadProjectTypes]);

    const loadProjectTypes = useCallback(() => {
        projectTypeService.getAllProjectTypes().then(res => {
            setProjectTypes(res);

            return res;
        }).then(projType => {
            fetchTierForUser(projType)
        })
    })

    const editProjectTypes = (id) => {
        projectTypeService.getProjectTypeById(id).then(res => {
            setProjectTypeData(res);
            setUpdateProjectTypeModalVisible(true);
        })
    }

    const manageProjectTypes = (id) => {
        projectTypeService.getProjectTypeById(id).then(res => {
            setProjectTypeId(id);
            setProjectTypeData(res);
            setManageProjectTypeModalVisible(true);
        })
    }

    const fetchTierForUser = (projType) => {
        if (projType.length) {
            clientTierService.getClientTierById(projType[0].clientTierId).then(res => {
                setTier(res);
            });
        }
    }
    // const deleteProjectType = (projectTypeId) => {
    //     projectTypeService.deleteProjectType(projectTypeId).then(res => {
    //         showAlert({ message: res.message, variant: 'success' })
    //         hideConfirmModal();
    //         loadProjectTypes();
    //     }).catch(ex => {
    //         showAlert({ message: ex.message, variant: 'error' })
    //     })
    // }
    const deleteProjectType = (projectTypeId, clientId, projectType) => {
        let newModel = { ...deleteProjectTypeModel, isDeleted: true, id: projectTypeId, clientId: clientId, projectType: projectType };
        projectTypeService.deleteProjectTypeIsDeletedFlag(newModel).then(res => {
            showAlert({ message: res.message, variant: 'success' })
            hideConfirmModal();
            loadProjectTypes();
        }).catch(ex => {
            showAlert({ message: ex.message, variant: 'error' })
        })
    }

    const navigateToPointCategories = (id) => {
        projectTypeService.getProjectTypeById(id).then(res => {
            history.push({
                pathname: `/settings/point-category-listing/${id}`,
                state: { projectTypeId: id, projectType: res }
            });
        })
    }

    const columns = [
        {
            name: "name",
            label: "Type",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
            },
        },
        {
            name: "client",
            label: "Client",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <p>{projectTypes[dataIndex].client}</p>
                    )
                }
            },
        },
        {
            name: "id",
            label: "Actions",
            options: {
                filter: false,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            <button
                                className="btn btn-success btn-hover-success btn-sm mr-4"
                                onClick={() => navigateToPointCategories(projectTypes[dataIndex].id)}
                            >
                                Manage
                            </button>
                            <button
                                className="btn btn-info btn-hover-info btn-sm mr-4"
                                onClick={() =>
                                    editProjectTypes(projectTypes[dataIndex].id)
                                }
                            >
                                Edit
                            </button>
                            {
                                (auth.role === "SuperAdmin" || auth.role === "Admin" || auth.role === 'Manager') ?
                                    <a href
                                        className="btn btn-danger btn-hover-danger btn-sm mr-4"
                                        onClick={() => showConfirmModal({
                                            title: "Delete Nspection Type",
                                            text: "Are you sure you want to delete this nspection Type?",
                                            rightBtnText: "Confirm",
                                            btnAction: () => deleteProjectType(projectTypes[dataIndex].id, projectTypes[dataIndex].clientId, projectTypes[dataIndex].projectTypes)
                                        })
                                        }
                                    >
                                        Delete
                                    </a>
                                    : <></>
                            }
                        </>
                    );
                }
            }
        }
    ];

    return (
        <>
            <div className="row">
                <div className="col-lg-12 col-xxl-12">
                    <div className={`card card-custom card-stretch gutter-b}`}>
                        <div className="card-header bordmedia/svg/icons/Communication/Contact1.svger-0 py-5">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label font-weight-bolder text-dark">
                                    Nspection Type
                                </span>
                                <span className="text-muted mt-3 font-weight-bold fz-xs">
                                    View and Manage Active Nspection Types
                                </span>
                            </h3>
                            <div className="card-toolbar">
                                {
                                    (auth.role === "SuperAdmin" || auth.role === "Admin" || auth.role === 'Manager') ?
                                        <button
                                            onClick={() => setAddProjectTypeModalVisible(true)}
                                            className="btn btn-success font-weight-bolder font-size-sm mr-3"
                                        >
                                            <span className="fa fa-plus"></span> Add Nspection Type
                                        </button>
                                        : <></>
                                }
                            </div>
                        </div>
                        <div className="container-fluid">
                            <MUIDataTable
                                data={projectTypes}
                                columns={columns}
                                options={{
                                    selectableRows: 'none'
                                }}
                            />
                            {
                                (auth.role === "SuperAdmin" || auth.role === "Admin" || auth.role === 'Manager') &&
                                <>
                                    {/* <ManageProjectTypeModal
                                        tierPlan={tier}
                                        data={projectTypeData}
                                        projectTypeId={projectTypeId}
                                        show={manageProjectTypeModalVisible}
                                        hide={() => setManageProjectTypeModalVisible(false)}
                                        reloadTable={() => loadProjectTypes()}
                                    /> */}
                                    <UpdateProjectTypeModal
                                        data={projectTypeData}
                                        show={updateProjectTypeModalVisible}
                                        hide={() => setUpdateProjectTypeModalVisible(false)}
                                        reloadTable={() => loadProjectTypes()}
                                    />
                                    <NewProjectTypeModal
                                        show={addProjectTypeModalVisible}
                                        hide={() => setAddProjectTypeModalVisible(false)}
                                        reloadTable={() => loadProjectTypes()}
                                    />
                                </>
                            }

                        </div>
                    </div>
                </div>
            </div>
            <br />
        </>
    )
}

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps, { ...alert.actions, ...confirmModal.actions })(ProjectTypeListingPage);
import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = "Setting/";

const getSettingById = async (id) => await axiosInstance.get(ENDPOINTORIGIN + 'GetSettingById?id=' + id, constants.REQUIRE_INTERCEPTORS);

const getSettingByKey = async (key) => await axiosInstance.get(ENDPOINTORIGIN + 'GetSettingByKey?key=' + key, constants.REQUIRE_INTERCEPTORS);

const getSettings = async () => await axiosInstance.get(ENDPOINTORIGIN + 'GetSettings', constants.REQUIRE_INTERCEPTORS);

const saveSetting = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'SaveSetting', model, constants.REQUIRE_INTERCEPTORS);

const deleteSetting = async (id) => await axiosInstance.get(ENDPOINTORIGIN + 'DeleteSetting?id=' + id, constants.REQUIRE_INTERCEPTORS);



export {
    getSettingById,
    getSettingByKey,
    getSettings,
    saveSetting,
    deleteSetting
}
import React, {useState} from 'react';
import { Link, useLocation } from 'react-router-dom';
import GenerateNewReportPage from '../services/project/GenerateNewReportPage';

const ProjectReportPage = (props) => {
    const useQueryString = () => new URLSearchParams(useLocation().search);
    const [token, setToken] = useState(useQueryString().get('token'));

    return (
        <div className="container-fluid">
            <GenerateNewReportPage isReadOnly={true} token={token}/>
        </div>
    )
}

export default ProjectReportPage;
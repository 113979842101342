import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import constants from '../../constants/constants';
import { useFormik } from "formik";
import { FilePond } from 'react-filepond';
import { makeStyles, withStyles } from '@material-ui/core';
import 'filepond/dist/filepond.min.css';
import { red, blue } from '@material-ui/core/colors';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import * as alert from '../../redux/alertSnackBarRedux';
import ProjectPinAnonymousValidator from '../../helpers/validators/ProjectPinAnonymousValidator';
import * as projectService from '../../services/service/projectService';
import * as projectPinService from '../../services/service/projectPinService';
import { getErrorField, validateDescription, convertJsonToFormData } from '../../helpers/helper';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const useStyles = makeStyles((theme) => ({
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const RedCheckbox = withStyles({
    root: {
        color: red[400],
        '&$checked': {
            color: red[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const BlueCheckBox = withStyles({
    root: {
        color: blue[400],
        '&$checked': {
            color: blue[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const NewAnonymouseImageUploadModal = (props) => {
    const { show, hide, showAlert, token } = props;
    const [imageDataUrl, setImageDataUrl] = useState('');
    const [project, setProject] = useState({
        id: 0,
        clientId: 0,
        projectName: '',
        scanDate: null,
        modelLink: '',
        notes: '',
        completedDateTime: null,
        client: {},
        projectPins: [],
        projectModelImages: []
    });
    const [projectPin, setProjectPin] = useState({
        id: 0,
        projectId: '',
        pointCategoryId: '',
        projectTypePOIStatusId: '',
        anonymousUser: '',
        title: '',
        description: '',
        isCritical: false,
        isOsha: false,
        isApproved: false,
        isResolved: false,
        projectPinCheckBoxes: [],
        pointCategory: null,
        projectTypePOIStatus: null,
        projectPinCustomFields: []
    });
    const [imagePath, setImagePath] = useState('');
    const [projectTypeCustomFieldList, setProjectTypeCustomFieldList] = useState([]);
    const [filteredProjectPinCustomFields, setFilteredProjectPinCustomFields] = useState([]);
    const [customFields, setCustomFields] = useState([]);
    const [formSubmit, setFormSubmit] = useState(false);
    const [projectType, setProjectType] = useState({});
    const [pointCategoryList, setPointCategoryList] = useState([]);
    const [poiStatusList, setPoiStatusList] = useState([]);
    const [projectPinCheckBoxes, setProjectPinCheckBoxes] = useState([]);
    const classes = useStyles();


    useEffect(() => {
        if (token) {
            fetchProjectDataForSharable(token);
        }
    }, []);

    const handleFilePondUpdate = (files) => {
        setImagePath(files);
        if (files.length > 0) {
          const file = files[0].file;
          const reader = new FileReader();
          reader.onload = function(e) {
            setImageDataUrl(e.target.result);
          };
          reader.readAsDataURL(file);
        } else {
          setImageDataUrl('');
        }
      };

    const fetchProjectDataForSharable = (token) => {
        projectService.getProjectIdForAnonymousUser(token).then(res => {
            setProject(res);
            setProjectType(res.projectType);
            setPointCategoryList(res.projectType.pointCategories);
            setProjectTypeCustomFieldList(res.projectType.projectTypeCustomFields);
            setPoiStatusList(res.projectType.projectTypePOIStatuses);
            copyProjectTypeCustomFieldToProjectPinCustomField(res.projectType.projectTypeCustomFields);
            copyProjectTypeToProjectPinCheckBoxes(res.projectType.projectTypeCheckBoxes);
            return res;
        }).catch(err => console.log(err))
    }

    const hideModal = () => {
        formik.resetForm();
        hide();
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: projectPin,
        validationSchema: ProjectPinAnonymousValidator,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setFormSubmit(true);
            await savePoint(values, setSubmitting);
        }
    });

    const handlePointCategoryChange = (selectedPointCategoryId) => {
        // Get associated ProjectTypeCustomField IDs for the selected PointCategory
        const associatedCustomFieldIds = projectTypeCustomFieldList
            .filter(field => field.pointCategoryProjectTypeCustomFields
                .some(assoc => assoc.pointCategoryId === selectedPointCategoryId))
            .map(field => field.id);

        console.log(filteredProjectPinCustomFields)
        // Filter ProjectPinCustomFields based on the associated ProjectTypeCustomFields
        const filteredFields = customFields
            .filter(field => associatedCustomFieldIds.includes(field.projectTypeCustomFieldId));

        setFilteredProjectPinCustomFields(filteredFields);
    };

    const handleDescriptionChange = (e, index) => {
        const updatedFields = [...filteredProjectPinCustomFields];
        const field = updatedFields[index];

        if (field.customFieldType.toLowerCase() === 'dropdown') {
            field.description = e.target.value;
        } else if (field.customFieldType.toLowerCase() === 'multiselect') {
            field.description = e.target.value.join(', ');
        } else {
            field.description = e.target.value;
        }

        // setCustomFields(updatedFields);
        setFilteredProjectPinCustomFields(updatedFields);
    };

    const copyProjectTypeCustomFieldToProjectPinCustomField = (projTypeCustomFields) => {
        let tempArr = projTypeCustomFields.map(x => {
            const clonedObj = JSON.parse(JSON.stringify(x));
            var projectTypeCustomFieldId = clonedObj['id'];
            delete clonedObj['id'];
            delete clonedObj['projectTypeId'];
            return { ...clonedObj, projectTypeCustomFieldId: projectTypeCustomFieldId };
        });
        setCustomFields(tempArr);
    }

    const copyProjectTypeToProjectPinCheckBoxes = (projTypeCheckBoxes) => {
        let tempArr = [...projTypeCheckBoxes];
        tempArr = tempArr.map(x => {
            delete x['id'];
            delete x['projectTypeId'];
            return { ...x, isChecked: false }
        });

        setProjectPinCheckBoxes(tempArr);
    }

    const hasCustomFieldErrors = () => {
        for (let index = 0; index < filteredProjectPinCustomFields.length; index++) {
            const field = filteredProjectPinCustomFields[index];
            if (validateDescription(field.description, field.customFieldType, field.options)) {
                return true;
            }
        }
        return false;
    };

    const handleCheckboxChange = (e, index, option) => {
        const updatedFields = [...filteredProjectPinCustomFields];
        let selectedOptions = updatedFields[index].description ? updatedFields[index].description.split(', ') : [];

        if (e.target.checked) {
            selectedOptions = [...selectedOptions, option];
        } else {
            selectedOptions = selectedOptions.filter(item => item !== option);
        }

        updatedFields[index].description = selectedOptions.join(', ');
        // setCustomFields(updatedFields);
        setFilteredProjectPinCustomFields(updatedFields);
    };

    const savePoint = async (values, setSubmitting) => {
        var model = convertJsonToFormData({
            ...values,
            MatterPointCoordinates: '',
            imageUrl: '',
            // projectPinCheckBoxes: projectPinCheckBoxes,
            // projectPinCustomFields: filteredProjectPinCustomFields,
            token: token
        });
        model.append('uploadedImage', imagePath && imagePath.length > 0 ? imagePath[0].file : null);
        var data = {
            projectPinCheckBoxes: projectPinCheckBoxes,
            projectPinCustomFields: filteredProjectPinCustomFields
        };
        await projectPinService.saveProjectPinAnonymous(model, data)
            .then(async res => {
                showAlert({ message: res.message, variant: 'success' });
            }).catch(ex => {
                showAlert({ message: ex.message, variant: 'error' });
            }).finally(() => {
                
                let temp = [...projectPinCheckBoxes];
                setProjectPinCheckBoxes(temp.map(x => { return { ...x, isChecked: false } }));
                setSubmitting(false);
                copyProjectTypeCustomFieldToProjectPinCustomField(projectTypeCustomFieldList);
                hideModal();
            });
    }


    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={hideModal}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">New Project Pin</Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <div className="card-body pt-0 pb-lg-18">
                        <div className={`tab-content matterport-point-window`}>
                            <div className="add-point-container">
                                <FormControl className="col-lg-12">
                                    <TextField
                                        error={getErrorField(formik, "anonymousUser")}
                                        id="outlined-name"
                                        label="Full Name"
                                        margin="normal"
                                        variant="outlined"
                                        {...formik.getFieldProps("anonymousUser")}
                                    />

                                    {formik.touched.anonymousUser && formik.errors.anonymousUser ? (
                                        <div className="text-danger">
                                            {formik.errors.anonymousUser}
                                        </div>
                                    ) : null}
                                </FormControl>
                                &nbsp;
                                <FormControl variant="outlined" className={`col-lg-12 ${classes.formControl}`}>
                                    <InputLabel htmlFor="outlined-age-native-simple">N-Point Category</InputLabel>
                                    <Select
                                        error={getErrorField(formik, "pointCategoryId")}
                                        native
                                        label="N-Point Category"
                                        inputProps={{
                                            name: 'category',
                                            id: 'outlined-age-native-simple',
                                        }}
                                        {...formik.getFieldProps("pointCategoryId")}
                                        onChange={(e) => {
                                            formik.setFieldValue("pointCategoryId", e.target.value === "" ? "" : Number(e.target.value));
                                            handlePointCategoryChange(Number(e.target.value));
                                        }}
                                    >
                                        <option aria-label="None" value="" />
                                        {
                                            pointCategoryList.map(x => <option key={x.id} value={x.id}>{x.name}</option>)
                                        }
                                    </Select>
                                    {formik.touched.pointCategoryId && formik.errors.pointCategoryId ? (
                                        <div className="text-danger">
                                            {formik.errors.pointCategoryId}
                                        </div>
                                    ) : null}
                                </FormControl>
                                &nbsp;
                                &nbsp;

                                <FormControl variant="outlined" className={`col-lg-12 ${classes.formControl}`}>
                                    <InputLabel htmlFor="outlined-age-native-simple">N-Point Status</InputLabel>
                                    <Select
                                        error={getErrorField(formik, "projectTypePOIStatusId")}
                                        native
                                        label="N-Point Status"
                                        inputProps={{
                                            name: 'POI Status',
                                            id: 'outlined-age-native-simple',
                                        }}
                                        {...formik.getFieldProps("projectTypePOIStatusId")}
                                        onChange={e => {
                                            formik.setFieldValue("projectTypePOIStatusId", e.target.value === "" ? "" : Number(e.target.value));
                                        }}
                                    >
                                        <option aria-label="None" value="" onChange={(e) => console.log(e)} />
                                        {
                                            poiStatusList.map(x => <option key={x.id} value={x.id}>{x.name}</option>)
                                        }
                                    </Select>
                                    {formik.touched.projectTypePOIStatusId && formik.errors.projectTypePOIStatusId ? (
                                        <div className="text-danger">
                                            {formik.errors.projectTypePOIStatusId}
                                        </div>
                                    ) : null}
                                </FormControl>

                                <FormControl className="col-lg-12">
                                    <TextField
                                        error={getErrorField(formik, "title")}
                                        id="outlined-name"
                                        label="Title"
                                        margin="normal"
                                        variant="outlined"
                                        {...formik.getFieldProps("title")}
                                    />

                                    {formik.touched.title && formik.errors.title ? (
                                        <div className="text-danger">
                                            {formik.errors.title}
                                        </div>
                                    ) : null}
                                </FormControl>


                                <FormControl className="col-lg-12">
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        label="Description"
                                        multiline
                                        rowsMax="4"
                                        margin="normal"
                                        variant="outlined"
                                        className="large-textarea"
                                        {...formik.getFieldProps("description")}
                                    />
                                </FormControl>


                                <FormControl variant="outlined" className={`col-lg-12 ${classes.formControl}`}>
                                    {/* <div className="selected-chips-container">
                                                {customFieldsData.map((field) => (
                                                    <Chip
                                                        key={field.id}
                                                        label={`${field.name}: ${field.description}`}
                                                        onClick={() => handleEditCustomField(field)}
                                                        onDelete={() => handleDeleteCustomField(field.id)}
                                                        className="selected-chip"
                                                    />
                                                ))}
                                            </div> */}


                                    <div>
                                        <ExpansionPanel>
                                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                <Typography variant="h6">Custom Fields</Typography>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails>
                                                <div className="col-lg-12">
                                                    {filteredProjectPinCustomFields && filteredProjectPinCustomFields.length > 0 && filteredProjectPinCustomFields.map((field, index) => {
                                                        const validationError = validateDescription(field.description, field.customFieldType, field.options);
                                                        let inputComponent;

                                                        switch (field.customFieldType.toLowerCase()) {
                                                            case 'dropdown':
                                                                inputComponent = (
                                                                    <Select
                                                                        value={field.description || ''}
                                                                        onChange={(e) => handleDescriptionChange(e, index)}
                                                                        displayEmpty
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                    >
                                                                        {field.customFieldTypeOptions.split(',').map((option, idx) => (
                                                                            <MenuItem key={idx} value={option.trim()}>
                                                                                {option}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                );
                                                                break;
                                                            case 'checkbox':
                                                                inputComponent = field.customFieldTypeOptions.split(',').map((option, idx) => (
                                                                    <div className="col-lg-12 d-flex justify-content-between flex-column pt-4">
                                                                        <FormControlLabel
                                                                            key={idx}
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={field.description?.split(', ').includes(option.trim()) || false}
                                                                                    onChange={(e) => handleCheckboxChange(e, index, option.trim())}
                                                                                    name={field.name}
                                                                                />
                                                                            }
                                                                            label={option.trim()}
                                                                        />
                                                                    </div>
                                                                ));
                                                                break;
                                                            case 'multiselect':
                                                                inputComponent = (
                                                                    <Select
                                                                        multiple
                                                                        value={field.description ? field.description.split(', ') : []}
                                                                        onChange={(e) => handleDescriptionChange(e, index)}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                    >
                                                                        {field.customFieldTypeOptions.split(',').map((option, idx) => (
                                                                            <MenuItem key={idx} value={option.trim()}>
                                                                                {option}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>

                                                                );
                                                                break;
                                                            default:
                                                                inputComponent = (
                                                                    <TextField
                                                                        label="Description"
                                                                        value={field.description || ''}
                                                                        onChange={(e) => handleDescriptionChange(e, index)}
                                                                        multiline={field.customFieldType.toLowerCase().includes('text')}
                                                                        rowsMax={field.customFieldType.toLowerCase().includes('text') ? "4" : "1"}
                                                                        margin="normal"
                                                                        variant="outlined"
                                                                        className="large-textarea"
                                                                        InputLabelProps={field.customFieldType.toLowerCase().includes('date') ? { shrink: true } : undefined}
                                                                        type={field.customFieldType.toLowerCase().includes('date') ? 'date' : 'text'}
                                                                    />
                                                                );
                                                        }

                                                        return (
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <FormControl variant="outlined" style={{ width: '100%', marginTop: 15 }}>
                                                                        <h5>{field.name}</h5>
                                                                        {field.customFieldType && <h6>({field.customFieldType})</h6>}
                                                                        {inputComponent}
                                                                        {validationError && <div className="text-danger">{validationError}</div>}
                                                                    </FormControl>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>

                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </div>
                                    {/* <Select
                                                labelId="demo-multiple-name-label"
                                                id="demo-multiple-name"
                                                multiple
                                                value={selectedCustomFieldIds}
                                                onChange={handleSelectChange}
                                                renderValue={selected => selected.map(value => projectTypeCustomFieldList.find(item => item.id === value)?.name).join(', ')}
                                            >
                                                {projectTypeCustomFieldList?.filter(field => !selectedCustomFieldIds.includes(field.id)).map((field) => (
                                                    <MenuItem key={field.id} value={field.id}>
                                                        {field.name}
                                                    </MenuItem>
                                                ))}
                                            </Select> */}
                                </FormControl>

                                <FormControl className="col-lg-12 d-flex justify-content-between flex-column pt-4">
                                    <div className="checkbox-container">
                                        {
                                            projectPinCheckBoxes.map((curr, index) =>
                                                <div key={index} className="checkbox-block">
                                                    <FormControlLabel
                                                        control={
                                                            <RedCheckbox
                                                                checked={curr.isChecked}
                                                                onChange={(e, val) => {
                                                                    let tempArr = [...projectPinCheckBoxes];
                                                                    tempArr.find(x => x.name === curr.name).isChecked = val;

                                                                    setProjectPinCheckBoxes(tempArr);
                                                                }}
                                                            />
                                                        }
                                                        label={curr.name}
                                                        labelPlacement="end"
                                                    />
                                                </div>
                                            )
                                        }
                                    </div>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    {imagePath ?
                        <>
                            <div
                                className="image-input-wrapper background-image-container"
                                style={{ backgroundImage: `url(${imageDataUrl})`, height: '200px' }}
                            />
                            <button
                                type="button"
                                onClick={() => setImagePath('')}
                                className="btn btn-info w-100 mt-lg-5 mb-lg-5"
                            >
                                Change Image
                            </button>
                        </>
                        :
                        <>
                            <FilePond
                                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                onupdatefiles={handleFilePondUpdate}
                            />
                        </>
                    }
                    {/* Add your form fields here */}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={hideModal}>Cancel</Button>
                    <Button
                        disabled={formik.isSubmitting || (formik.touched && !formik.isValid) || hasCustomFieldErrors()}
                        variant="primary" type="submit">Save Changes</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default connect(null, alert.actions)(NewAnonymouseImageUploadModal);

/* eslint-disable no-restricted-imports */
import React, { useState, useEffect, useCallback } from 'react';
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import * as settingService from "../../../services/settings/settingService";
import { Button, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import SettingValidator from "../../../helpers/validators/SettingValidator";
import { getErrorField } from "../../../helpers/helper";
import * as alert from "../../../redux/alertSnackBarRedux";
import * as confirmModal from "../../../redux/confirmModalRedux";
import TextField from '@material-ui/core/TextField';

var initialSetting = {
    id: 0,
    key: '',
    value: '',
}

const SettingListingPage = (props) => {
    const { showAlert, showConfirmModal, hideConfirmModal, history } = props;
    const [data, setData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedSetting, setSelectedSetting] = useState(initialSetting);

    useEffect(() => {
        loadTableData();
    }, [loadTableData])

    const loadTableData = useCallback(() => {
        settingService.getSettings().then(res => {
            setData(res);
        }).catch((ex) => {
            showAlert({ message: ex.message, variant: "error" });
        });
    })

    const editSetting = (id) => {
        settingService.getSettingById(id).then(res => {
            setSelectedSetting(res);
            setIsModalOpen(true);
        })
    }

    const deleteSetting = (id) => {
        settingService.deleteSetting(id).then(res => {
            showAlert({ message: res.message, variant: "success" });
            loadTableData();
            hideConfirmModal();
        })
    }

    const columns = [
        {
            name: "key",
            label: "Key",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "value",
            label: "Value",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            {data[dataIndex].value ? data[dataIndex].value : "-"}
                        </>
                    );
                }
            }
        },
        {
            name: "id",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                width: 1,
                download: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            <button
                                className="btn btn-sm btn-info font-weight-bold py-2 px-3 px-xxl-5 my-1"
                                onClick={() => editSetting(data[dataIndex].id)}
                            >
                                Edit
                            </button> &nbsp;
                            <button
                                onClick={() => showConfirmModal({
                                    title: "Delete Setting",
                                    text: "Are you sure you want to delete this setting?",
                                    rightBtnText: "Confirm",
                                    btnAction: () => deleteSetting(data[dataIndex].id)
                                })}
                                className="btn btn-sm btn-danger font-weight-bold py-2 px-3 px-xxl-5 my-1"
                            >
                                Delete
                            </button>
                        </>
                    );
                }
            },

        },
    ];

    const handleModalClose = () => {
        setIsModalOpen(false);
        setSelectedSetting(initialSetting);
        formik.resetForm();
    }

    const saveSetting = (values, setStatus, setSubmitting) => {
        settingService.saveSetting({ ...values }).then(res => {
            showAlert({ message: res.message, variant: "success" });
            loadTableData();
            handleModalClose();
        }).catch((ex) => {
            showAlert({ message: ex.message, variant: "error" });
        })
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: selectedSetting,
        validationSchema: SettingValidator,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            saveSetting(values, setStatus, setSubmitting);
        }
    });

    return (
        <div className="row" >
            <Modal centered size="md" show={isModalOpen} onHide={handleModalClose}>
                <form className="card card-custom" onSubmit={formik.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Setting</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form">
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <TextField
                                        error={getErrorField(formik, "key")}
                                        id="outlined-name"
                                        label="Title"
                                        margin="normal"
                                        variant="outlined"
                                        {...formik.getFieldProps("key")}
                                    />
                                    {formik.touched.key && formik.errors.key ? (
                                        <div className="text-danger">
                                            {formik.errors.key}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <TextField
                                        error={getErrorField(formik, "value")}
                                        id="outlined-name"
                                        label="Value"
                                        multiline
                                        margin="normal"
                                        variant="outlined"
                                        {...formik.getFieldProps("value")}
                                    />
                                    {formik.touched.value && formik.errors.value ? (
                                        <div className="text-danger">
                                            {formik.errors.value}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                            Close
                        </Button>
                        <button
                            type="submit"
                            className="btn btn-success mr-2"
                            disabled={
                                formik.isSubmitting || (formik.touched && !formik.isValid)
                            }
                        >
                            Save
                            {formik.isSubmitting}
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
            <div className="col-lg-12 col-xxl-12">
                <div className={`card card-custom card-stretch gutter-b}`}>
                    <div className="card-header border-0 py-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label font-weight-bolder text-dark">
                                Setting
                            </span>
                            <span className="text-muted mt-3 font-weight-bold fz-xs">
                                View and Manage Settings
                            </span>
                        </h3>
                        <div className="card-toolbar">
                            <button
                                onClick={() => setIsModalOpen(true)}
                                className="btn btn-success font-weight-bolder font-size-sm mr-3"
                            >
                                <span className="fa fa-plus"></span> Add Setting
                            </button>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <MUIDataTable
                            data={data}
                            columns={columns}
                            options={{
                                selectableRows: 'none'
                            }}
                        />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default connect(null, { ...alert.actions, ...confirmModal.actions })(SettingListingPage);
import React, { useState, useEffect, useRef } from 'react';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import MUIDataTable from "mui-datatables";
import { Modal, Button } from 'react-bootstrap';
import { TextField, Checkbox, LinearProgress } from '@material-ui/core';
import { FilePond } from "react-filepond";
import * as projectService from '../../../../services/service/projectService';
import * as helper from '../../../../helpers/helper';
import constants from '../../../../constants/constants';

const ProjectDocumentPage = (props) => {
    const { showAlert, showConfirmModal, hideConfirmModal } = props;
    const [isLoading, setIsLoading] = useState(false);
    const { id } = useParams();
    const defaultFileModel = {
        id: 0,
        projectId: id,
        name: "",
        description: '',
        path: '',
        isLink: false,
        file: null
    }
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [document, setDocument] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [fileModel, setFileModel] = useState(defaultFileModel);

    useEffect(() => {
        fetchProjectDocuments();
        // eslint-disable-next-line no-use-before-define
    }, [fetchProjectDocuments]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchProjectDocuments = async () => {
        setIsLoading(true);
        try {
            const res = await projectService.getProjectDocuments(id);
            setDocuments(res);
        } catch (error) {
            showAlert({ message: error.message, variant: "danger" });
        } finally {
            setIsLoading(false);
        }
    }

    const deleteDocument = async (id) => {
        try {
            showConfirmModal({
                title: "Delete Document",
                text: "Are you sure you want to delete this document?",
                rightBtnText: "Confirm",
                btnAction: async () => {
                    hideConfirmModal();
                    setIsLoading(true);
                    const res = await projectService.deleteProjectDocument(id);
                    showAlert({ message: res.message, variant: "success" });
                    await fetchProjectDocuments();
                }
            })
        } catch (error) {
            showAlert({ message: error.message, variant: "danger" });
        } finally {
            setIsLoading(false);
        }
    }

    const handleEdit = (data) => {
        setFileModel(data);
        setIsModalOpen(true);
    }

    const defaultOptions = {
        filter: true,
        sort: true,
        width: 1,
        download: true,
    };

    const handleUrl = (data) => {
        if (data.isLink) {
            const appendHttps = data.path.startsWith('http') || data.path.startsWith('https') ? '' : 'https://';
            window.open(appendHttps + data.path, '_blank');
        } else {
            window.open(`${constants.API_URL}api/${data.path}`, '_blank');
        }
    }

    const columns = [
        {
            name: "name",
            label: "Name",
            options: defaultOptions,
        },
        {
            name: "description",
            label: "Description",
            options: defaultOptions,
        },
        {
            name: "uploadedBy",
            label: "Created By",
            options: defaultOptions,
        },
        {
            name: "createDateTime",
            label: "Created Date",
            options: {
                ...defaultOptions,
                customBodyRenderLite: (dataIndex) => {
                    return format(new Date(documents[dataIndex].createDateTime), 'dd/MM/yyyy hh:mm a');
                }
            },
        },
        {
            name: "id",
            label: "Action",
            options: {
                ...defaultOptions,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            <a className='btn ml-5 btn-info' onClick={() => handleUrl(documents[dataIndex])}>View</a>
                            <a className='btn ml-5 btn-warning' onClick={() => handleEdit(documents[dataIndex])} download={documents[dataIndex].name} >Edit</a>
                            <a className='btn ml-5 btn-primary' onClick={() => deleteDocument(documents[dataIndex].id)} >Delete</a>
                        </>

                    );
                },
            },
        }
    ];

    const handleModalClose = () => {
        setIsModalOpen(false);
        setFileModel(defaultFileModel);
    }

    const handleDocumentUpload = async () => {
        setIsLoading(true);
        try {
            handleModalClose();
            const res = await projectService.uploadProjectDocument(helper.convertJsonToFormData({ ...fileModel, file: document.length > 0 ? document[0].file : null }));
            showAlert({ message: res.message, variant: "success" });
            await fetchProjectDocuments();
        } catch (error) {
            showAlert({ message: error.message, variant: "danger" });
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="card-header border-0 py-5">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label font-weight-bolder text-dark">
                        Documents
                    </span>
                </h3>
                <div className="card-toolbar">
                    <button
                        onClick={() => setIsModalOpen(true)}
                        className="btn btn-success font-weight-bolder font-size-sm mr-3"
                    >
                        <span className="fa fa-plus"></span> Add Document
                    </button>
                </div>
            </div>
            {
                isLoading ?
                    <div className="col-xl-12 mt-lg-30">
                        <LinearProgress />
                    </div> : (
                        <div className="card card-custom">
                            <div className="card-body">
                                <MUIDataTable
                                    data={documents}
                                    columns={columns}
                                    options={{
                                        selectableRows: false,
                                    }}
                                />
                            </div>
                        </div>
                    )
            }


            <Modal centered size="md" show={isModalOpen} onHide={handleModalClose}>
                <div className="card card-custom">
                    <Modal.Header closeButton>
                        <Modal.Title>Document</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form">
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <TextField
                                        id="outlined-name"
                                        label="Name"
                                        margin="normal"
                                        variant="outlined"
                                        value={fileModel.name}
                                        onChange={({ target }) => setFileModel({ ...fileModel, name: target.value })}
                                    />
                                </div>
                            </div>
                            {
                                fileModel.isLink && (
                                    <div className="form-group row">
                                        <div className="col-lg-12">
                                            <TextField
                                                id="outlined-name"
                                                label="URL"
                                                margin="normal"
                                                variant="outlined"
                                                value={fileModel.path}
                                                onChange={({ target }) => setFileModel({ ...fileModel, path: target.value })}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        className="notes-textarea"
                                        label="Description"
                                        multiline
                                        rowsMax="4"
                                        margin="normal"
                                        variant="outlined"
                                        value={fileModel.description}
                                        onChange={({ target }) => setFileModel({ ...fileModel, description: target.value })}
                                    />
                                </div>
                            </div>
                            {
                                !fileModel.isLink && fileModel.id === 0 && (
                                    <div className='form-group row'>
                                        <div className="col-lg-12">
                                            <FilePond
                                                allowMultiple={true}
                                                maxFiles={3}
                                                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                                onupdatefiles={setDocument}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            {
                                fileModel.id === 0 && (
                                    <div className='form-group row'>
                                        <div className="col-lg-12"></div>
                                        <Checkbox
                                            checked={fileModel.isLink}
                                            onChange={({ target }) => setFileModel({ ...fileModel, isLink: target.checked })}
                                            name="isLink"
                                            color="primary"
                                        />
                                        <label className='d-flex align-items-center'>
                                            Is Link?
                                        </label>
                                    </div>
                                )
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                            Close
                        </Button>
                        <button
                            type="button"
                            className="btn btn-success mr-2"
                            onClick={handleDocumentUpload}
                        >
                            Save
                        </button>
                    </Modal.Footer>
                </div>
            </Modal>
        </>

    )
}

export default ProjectDocumentPage;
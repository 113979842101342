import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import * as projectTypeMetaDataFieldService from "../../../services/settings/projectTypeMetaDataFieldService";
import { Button, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import ProjectTypeMetaDataFieldValidator from "../../../helpers/validators/ProjectTypeMetaDataFieldValidator";
import { getErrorField } from "../../../helpers/helper";
import * as alert from "../../../redux/alertSnackBarRedux";
import * as confirmModal from "../../../redux/confirmModalRedux";
import TextField from '@material-ui/core/TextField';
import Container from 'react-bootstrap/Container';
import ProjectTypeNavbar from '../../components/ProjectTypeNavbar';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const initialProjectTypeMetaDataField = {
    id: 0,
    question: '',
    projectTypeId: '',
    isDeleted: false
}

const ProjectTypeMetaDataFieldListingPage = (props) => {
    const location = useLocation();
    const { id } = useParams();
    const { projectTypeId, projectType } = location.state || {};
    const { showAlert, showConfirmModal, hideConfirmModal } = props;
    const [data, setData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedField, setSelectedField] = useState(initialProjectTypeMetaDataField);

    useEffect(() => {
        loadTableData();
    }, [loadTableData])

    const loadTableData = useCallback(() => {
        projectTypeMetaDataFieldService.getProjectTypeMetaDataFieldByProjectTypeId(id).then(res => {
            setData(res);
            console.log(res);
        }).catch((ex) => {
            showAlert({ message: ex.message, variant: "error" });
        });
    }, [id, showAlert])

    const editField = (id) => {
        projectTypeMetaDataFieldService.getProjectTypeMetaDataFieldById(id).then(res => {
            setSelectedField(res);
            setIsModalOpen(true);
        })
    }

    const deleteField = (id, projectTypeId) => {
        let newModel = { ...selectedField, id: id, projectTypeId: projectTypeId, isDeleted: true }
        projectTypeMetaDataFieldService.softDeleteProjectTypeMetaDataField(newModel).then(res => {
            showAlert({ message: res.message, variant: "success" });
            loadTableData();
            hideConfirmModal();
        })
    }

    const columns = [
        {
            name: "question",
            label: "Question",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "id",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            <button
                                className="btn btn-sm btn-info font-weight-bold py-2 px-3 px-xxl-5 my-1"
                                onClick={() => {
                                    editField(data[dataIndex].id);
                                }}
                            >
                                Edit
                            </button>
                            <button
                                onClick={() => showConfirmModal({
                                    title: "Delete Nspection Property",
                                    text: "Are you sure you want to delete this Nspection Property?",
                                    rightBtnText: "Confirm",
                                    btnAction: () => deleteField(data[dataIndex].id, data[dataIndex].projectTypeId)
                                })}
                                className="btn btn-sm btn-danger font-weight-bold py-2 px-3 px-xxl-5 my-1 ml-2"
                            >
                                Delete
                            </button>
                        </>
                    );
                }
            },
        },
    ];

    const handleModalClose = () => {
        setIsModalOpen(false);
        setSelectedField(initialProjectTypeMetaDataField);
        formik.resetForm();
    }

    const saveField = (values, setStatus, setSubmitting) => {
        let newField = { ...values, projectTypeId: id };
        projectTypeMetaDataFieldService.saveProjectTypeMetaDataField(newField).then(res => {
            showAlert({ message: res.message, variant: 'success' });
            loadTableData();
        }).catch((ex) => {
            showAlert({ message: ex.message, variant: "error" });
        }).finally(() => handleModalClose())
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: selectedField,
        validationSchema: ProjectTypeMetaDataFieldValidator,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            saveField(values, setStatus, setSubmitting);
        }
    });

    return (
        <div className="row">
            <Modal centered size="md" show={isModalOpen} onHide={handleModalClose}>
                <form className="card card-custom" onSubmit={formik.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Nspection Property</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form">
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <TextField
                                        error={getErrorField(formik, "question")}
                                        id="outlined-question"
                                        label="Question"
                                        margin="normal"
                                        variant="outlined"
                                        fullWidth
                                        {...formik.getFieldProps("question")}
                                    />
                                    {formik.touched.question && formik.errors.question ? (
                                        <div className="text-danger">
                                            {formik.errors.question}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                            Close
                        </Button>
                        <button
                            type="submit"
                            className="btn btn-success mr-2"
                            disabled={
                                formik.isSubmitting || (formik.touched && !formik.isValid)
                            }
                        >
                            Save
                            {formik.isSubmitting}
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
            <div className="col-lg-12 col-xxl-12">
                <div className={`card card-custom card-stretch gutter-b}`}>
                    <div className="card-header border-0 py-5">
                        <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                        Nspection Type Name: {projectType?.name}
                            </span>
                            <span className="card-label font-weight-bolder text-dark">
                                Nspection Properties
                            </span>
                        </h3>
                        <div className="card-toolbar">
                            <button
                                onClick={() => setIsModalOpen(true)}
                                className="btn btn-success font-weight-bolder font-size-sm mr-3"
                            >
                                <span className="fa fa-plus"></span> Add Nspection Property
                            </button>
                        </div>
                    </div>
                    <ProjectTypeNavbar id={id} projectType={projectType} />
                    <div className="container-fluid">
                        <MUIDataTable
                            data={data}
                            columns={columns}
                            options={{
                                selectableRows: 'none'
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect(null, { ...alert.actions, ...confirmModal.actions })(ProjectTypeMetaDataFieldListingPage);
/* eslint-disable no-restricted-imports */
import React, { useState } from 'react';
import { Modal, Button, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory, Link } from "react-router-dom";
import { checkTierPlanAndRoleTier2 } from '../../helpers/helper';
import * as alert from '../../redux/alertSnackBarRedux';

const initialState = {
    id: 0,
    name: '',
    description: '',
    clientId: '',
    isDeleted: false,
    client: {}
}
const ManageProjectTypeModal = (props) => {

    const { show, hide, projectTypeId, data, tierPlan, auth } = props;
    console.log('data', data);
    const hideModal = () => {
        hide();
    }

    return (
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={hideModal}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Manage
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Col>
                    <Row>
                        <Link className="btn btn-success btn-hover-success btn-block btn-lg"
                            to={{
                                pathname: "/settings/point-category-listing/" + projectTypeId,
                                state: { projectTypeId: projectTypeId, projectType:data },
                                // state: { projectId: activeProjects[dataIndex].id}
                            }}>N-Point Categories</Link>
                    </Row>
                    <br />
                    <Row>
                        <Link className="btn btn-success btn-hover-success btn-block btn-lg"
                            to={{
                                pathname: "/settings/projectType-checkbox/" + projectTypeId,
                                state: { projectTypeId: projectTypeId, projectType:data },
                                // state: { projectId: activeProjects[dataIndex].id}
                            }}>Checkboxes</Link>
                    </Row>
                    <br />
                    <Row>
                        <Link className="btn btn-success btn-hover-success btn-block btn-lg"
                            to={{
                                pathname: "/settings/poi-listing/" + projectTypeId,
                                state: { projectTypeId: projectTypeId, projectType:data },
                                // state: { projectId: activeProjects[dataIndex].id}
                            }}>N-Point Statuses</Link>
                    </Row>
                    <br />

                    <Row>
                        <Link className="btn btn-success btn-hover-success btn-block btn-lg"
                            to={{
                                pathname: "/settings/projectType-customField/" + projectTypeId,
                                state: { projectTypeId: projectTypeId, projectType:data },
                                // state: { projectId: activeProjects[dataIndex].id}
                            }}>Custom Fields</Link>
                    </Row>
                    <br />

                    <Row>
                        <Link className="btn btn-success btn-hover-success btn-block btn-lg"
                            to={{
                                pathname: "/settings/projectType-metaDataField/" + projectTypeId,
                                state: { projectTypeId: projectTypeId, projectType:data },
                                // state: { projectId: activeProjects[dataIndex].id}
                            }}>Nspection Properties</Link>
                    </Row>
                </Col>
            </Modal.Body>

            <Modal.Footer>
                <Button className="btn-light" onClick={hideModal}>Cancel</Button>
            </Modal.Footer>

        </Modal>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps, alert.actions)(ManageProjectTypeModal);
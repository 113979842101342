import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import MUIDataTable from "mui-datatables";
import NewProjectModal from "../../../components/NewProjectModal";
import * as alert from "../../../../redux/alertSnackBarRedux";
import * as confirmModal from "../../../../redux/confirmModalRedux";
import * as projectService from '../../../../services/service/projectService';
import * as projectTypeService from '../../../../services/service/projectTypeService';
import { Link } from 'react-router-dom';

const moment = require('moment');

const ProjectTypeEditPage = (props) => {
    const { showAlert, showConfirmModal, hideConfirmModal, auth, history } = props;
    const [addProjectModalVisible, setAddProjectModalVisible] = useState(false);
    const [activeProjects, setActiveProjects] = useState([]);
    const [completedProjects, setCompletedProjects] = useState([]);
    const [projectTypes, setProjectTypes] = useState([]);

    useEffect(() => {
        loadActiveProjectTypesTable();
        loadActiveProjectTable();
        loadCompletedProjectTable();
    }, [history]);

    const loadActiveProjectTable = () => {
        projectService.getProjects(false).then(res => {
            setActiveProjects(res);
        })
    }

    const loadCompletedProjectTable = () => {
        projectService.getProjects(true).then(res => {
            setCompletedProjects(res);
        })
    }

    const loadActiveProjectTypesTable = () => {
        projectTypeService.getAllProjectTypes().then(res=> {
            setProjectTypes(res);
        })
    }

    const columns = [
        {
            name: "projectName",
            label: "Nspection Name",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
            },
        },
        {
            name: "projectTypes",
            label: "Nspection Type",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <p>{projectTypes[dataIndex].name}</p>
                    )
                }
            },
        },
        {
            name: "clientId",
            label: "Client",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <p>{activeProjects[dataIndex].client.businessName}</p>
                    )
                }
            },
        },
        {
            name: "scanDate",
            label: "Scan Date",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <p>{moment(activeProjects[dataIndex].scanDate).format('MM/DD/yyyy')}</p>
                    )
                }
            },
        },
        
        {
            name: "id",
            label: "Actions",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            <Link
                                className="btn btn-info btn-hover-info btn-sm mr-3"
                                to={{
                                    pathname: "/project/edit-project/" + activeProjects[dataIndex].id,
                                    state: { projectId: activeProjects[dataIndex].id, projectTypeId: projectTypes[dataIndex].id},
                                    // state: { projectId: activeProjects[dataIndex].id}
                                }}
                            >
                                View Nspection
                            </Link>
                            {
                               (auth.role === "SuperAdmin"  || auth.role === "Admin" || auth.role === 'Manager') ?
                                    <a href
                                        className="btn btn-danger btn-hover-danger btn-sm mr-3"
                                        onClick={() => showConfirmModal({
                                            title: "Delete Nspection Type",
                                            text: "Are you sure you want to delete this nspection type?",
                                            rightBtnText: "Confirm",
                                            btnAction: () => deleteProject(activeProjects[dataIndex].id, false)
                                        })
                                        }
                                    >
                                        Delete Nspection
                                    </a>
                                    : <></>
                            }
                        </>
                    );
                }
            }
        }
    ];

    const completed_columns = [
        {
            name: "projectName",
            label: "Nspection Name",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
            },
        },
        {
            name: "projectTypes",
            label: "Nspection Type",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <p>{projectTypes[dataIndex].name}</p>
                    )
                }
            },
        },
        {
            name: "clientId",
            label: "Client",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <p>{completedProjects[dataIndex].client.businessName}</p>
                    )
                }
            },
        },
        {
            name: "scanDate",
            label: "Scan Date",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <p>{moment(completedProjects[dataIndex].scanDate).format('MM/DD/yyyy')}</p>
                    )
                }
            },
        },
        {
            name: "completedDateTime",
            label: "Completed Date",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <p>{moment(completedProjects[dataIndex].completedDateTime).format('MM/DD/yyyy')}</p>
                    )
                }
            },
        },
        {
            name: "id",
            label: "Actions",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            <Link
                                className="btn btn-info btn-hover-info btn-sm mr-3"
                                to={{
                                    pathname: "/project/edit-project/" + completedProjects[dataIndex].id,
                                    state: { projectId: completedProjects[dataIndex].id }
                                }}
                            >
                                View Nspection
                            </Link>
                            {
                                (auth.role === "SuperAdmin"  || auth.role === "Admin" || auth.role === 'Manager') ?
                                    <a href
                                        className="btn btn-danger btn-hover-danger btn-sm mr-3"
                                        onClick={() => showConfirmModal({
                                            title: "Delete Nspection Type",
                                            text: "Are you sure you want to delete this nspection type?",
                                            rightBtnText: "Confirm",
                                            btnAction: () => deleteProject(completedProjects[dataIndex].id, true)
                                        })
                                        }
                                    >
                                        Delete Nspection
                                    </a>
                                    : <></>
                            }
                        </>
                    );
                }
            }
        }
    ];

    const deleteProject = (projectId, isCompleted) => {
        projectService.deleteProject(projectId).then(res => {
            showAlert({ message: res.message, variant: 'success' })
            hideConfirmModal();
            if (isCompleted) {
                loadCompletedProjectTable();
            } else {
                loadActiveProjectTable();
            }
        }).catch(ex => {
            showAlert({ message: ex.message, variant: 'error' })
        })
    }

    return (
        <>
            <div className="row">
               
                <div className="col-lg-12 col-xxl-12">
                    <div className={`card card-custom card-stretch gutter-b}`}>
                        <div className="card-header bordmedia/svg/icons/Communication/Contact1.svger-0 py-5">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label font-weight-bolder text-dark">
                                    Active Nspections 
                            </span>
                                <span className="text-muted mt-3 font-weight-bold fz-xs">
                                    View and Manage Active Nspections
                            </span>
                            </h3>
                            <div className="card-toolbar">
                                {
                                   (auth.role === "SuperAdmin"  || auth.role === "Admin" || auth.role === 'Manager') ?
                                        <button
                                            onClick={() => setAddProjectModalVisible(true)}
                                            className="btn btn-success font-weight-bolder font-size-sm mr-3"
                                        >
                                            <span className="fa fa-plus"></span> Add Nspection
                                    </button>
                                        : <></>
                                }
                            </div>
                        </div>
                        <div className="container-fluid">
                            <MUIDataTable
                                data={activeProjects}
                                columns={columns}
                                options={{
                                    selectableRows: false
                                }}
                            />
                            {
                                (auth.role === "SuperAdmin"  || auth.role === "Admin" || auth.role === 'Manager') ?
                                    <NewProjectModal
                                        show={addProjectModalVisible}
                                        hide={() => setAddProjectModalVisible(false)}
                                        reloadTable={() => loadActiveProjectTable()}
                                    /> : <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <div className="row">
                <div className="col-lg-12 col-xxl-12">
                    <div className={`card card-custom card-stretch gutter-b}`}>
                        <div className="card-header bordmedia/svg/icons/Communication/Contact1.svger-0 py-5">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label font-weight-bolder text-dark">
                                    Completed Nspections
                            </span>
                                <span className="text-muted mt-3 font-weight-bold fz-xs">
                                    View and Manage Completed Nspections
                            </span>
                            </h3>
                        </div>
                        <div className="container-fluid">
                            <MUIDataTable
                                data={completedProjects}
                                columns={completed_columns}
                                options={{
                                    selectableRows: false
                                }}
                            />
                           
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps, { ...alert.actions, ...confirmModal.actions })(ProjectTypeEditPage);
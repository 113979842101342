/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-restricted-imports */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { validateDescription } from '../../../../helpers/helper';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Modal, Button, Form, Tabs, Tab } from 'react-bootstrap';
import { Carousel } from "react-responsive-carousel";
import { makeStyles, withStyles } from '@material-ui/core';
import { useFormik } from "formik";
import { Tooltip } from "react-tooltip";
import _ from 'lodash';
import { red, blue } from '@material-ui/core/colors';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import Annotation from 'react-image-annotation';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    RectangleSelector
} from 'react-image-annotation/lib/selectors'
import * as alert from "../../../../redux/alertSnackBarRedux";
import * as confirmModal from "../../../../redux/confirmModalRedux";
import UpdateProjectPinModal from '../../../components/UpdateProjectPinModal';

import * as projectService from '../../../../services/service/projectService';
import * as projectTypeService from '../../../../services/service/projectTypeService';
import * as projectPinService from '../../../../services/service/projectPinService';
import * as clientTierService from '../../../../services/management/clientTierService';

import ProjectPinValidator from '../../../../helpers/validators/ProjectPinValidator';
import { getErrorField, checkTierPlanAndRoleTier2, checkTierPlanAndRoleTier3 } from '../../../../helpers/helper';
import constants from '../../../../constants/constants';
import MUIDataTable from "mui-datatables";
import LinearProgress from '@material-ui/core/LinearProgress';
import Slider from '@material-ui/core/Slider';
import UploadImagesModal from '../../../components/UploadImagesModal';
import ProjectDocumentPage from './ProjectDocumentPage';

const moment = require('moment');
const useStyles = makeStyles((theme) => ({
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const RedCheckbox = withStyles({
    root: {
        color: red[400],
        '&$checked': {
            color: red[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const BlueCheckBox = withStyles({
    root: {
        color: blue[400],
        '&$checked': {
            color: blue[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const ProjectEditPage = (props) => {
    const annotationRef = useRef(null);
    const history = useHistory();
    const { id } = useParams();
    const historyProjectId = id ?? '';
    const { showAlert, showConfirmModal, hideConfirmModal, auth } = props;
    const [currentImageIndex, setCurrentImageIndex] = useState('');
    const [annotation, setAnnotation] = useState({});
    const [carouselIndex, setCarouselIndex] = useState(0);
    const [customFieldDescription, setCustomFieldDescription] = useState('');
    const [updateImagesModalVisible, setUpdateImagesModalVisible] = useState();
    const [annotations, setAnnotations] = useState([]);
    const [selectedCustomFieldIds, setSelectedCustomFieldIds] = useState([]);
    const [formJson, setFormJson] = useState(null);
    const [metaDataFields, setMetaDataFields] = useState([]);
    const [isSavingAnswers, setIsSavingAnswers] = useState(false);
    const [metaDataAnswers, setMetaDataAnswers] = useState({});
    const [project, setProject] = useState({
        id: 0,
        clientId: 0,
        projectName: '',
        scanDate: null,
        modelLink: '',
        notes: '',
        completedDateTime: null,
        client: {},
        projectPins: [],
        projectModelImages: []
    });
    const [projectPin, setProjectPin] = useState({
        id: 0,
        projectId: historyProjectId,
        pointCategoryId: '',
        projectTypePOIStatusId: '',
        title: '',
        description: '',
        isCritical: false,
        isOsha: false,
        isApproved: false,
        isResolved: false,
        projectPinCheckBoxes: [],
        pointCategory: null,
        projectTypePOIStatus: null,
        projectPinCustomFields: []
    });
    const [checked, setChecked] = useState(false);
    const [showAllChecked, setShowAllChecked] = useState(false);
    const [formSubmit, setFormSubmit] = useState(false);
    const [projectPinCheckBoxes, setProjectPinCheckBoxes] = useState([]);
    const [projectPinCustomField, setProjectPinCustomFields] = useState([]);
    const [projectType, setProjectType] = useState({});
    const [isBusinessOrProfessionalTier, setIsBusinessOrProfessionalTier] = useState(false);
    const [isBusinessTier, setIsBusinessTier] = useState(false);
    const [tier, setTier] = useState('');
    const [project3d, setProject3d] = useState(true);
    const [myIntervalId, setMyIntervalId] = useState('');
    const [selectedProjectTypeCustomField, setSelectedProjectTypeCustomField] = useState('');
    const [selectedProjectTypeCustomFields, setSelectedProjectTypeCustomFields] = useState([]);
    const [imageString, setImageString] = useState('');
    const [editProjectPinId, setEditProjectPinId] = useState(0);
    const [poiStatusName, setPoiStatusName] = useState('');
    const [selectedPoint, setSelectedPoint] = useState(null);
    const [customFieldsData, setCustomFieldsData] = useState([]);
    const [updateProjectPinModalVisible, setUpdateProjectPinModalVisible] = useState(false);
    const [pointCategoryList, setPointCategoryList] = useState([]);
    const [projectTypeCustomFieldList, setProjectTypeCustomFieldList] = useState([]);
    const [poiStatusList, setPoiStatusList] = useState([]);
    const [globalSdk, setGlobalSdk] = useState({});
    const [customFields, setCustomFields] = useState([]);
    const [filteredProjectPinCustomFields, setFilteredProjectPinCustomFields] = useState([]);
    const [projectTypeChangeDetector, setProjectTypeChangeDetector] = useState(false);
    const [fetchNewAnnotation, setFetchNewAnnotation] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editedCustomField, setEditedCustomField] = useState(null);
    const [btnPosition, setBtnPosition] = useState({
        left: 0,
        top: 0
    });
    const [useTransientTagEditor, setUseTransientTagEditor] = useState(false);
    const [highlightedAnnotationIndex, setHighlightedAnnotationIndex] = useState(null);
    const [displayBtn, setDisplayBtn] = useState(false);
    const [intersectionState, setIntersectionState] = useState({});
    const [savedProjectPins, setSavedProjectPins] = useState([]);
    const [isTableLoading, setIsTableLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('inspectionPoints');
    const [slider, setSlider] = useState(1);
    const iFrameRef = React.useRef();
    const classes = useStyles();

    var poseCache;
    var intersectionCache;
    var delayBeforeShow = 1000;
    var buttonDisplayed = true;

    useEffect(() => {
        fetchProject();
    }, [fetchProject, projectTypeChangeDetector]);

    useEffect(() => {
        if (!project3d) {
            if (showAllChecked) {
                fetchProjectPinsForAProjectAndImageId(project, '');
            }
            else {
                fetchProjectPinsForAProjectAndImageId(project, currentImageIndex);
            }
        }
    }, [currentImageIndex, fetchNewAnnotation, fetchProjectPinsForAProjectAndImageId, project, project3d, showAllChecked])

    const fetchProject = async () => {
        let is3d = project3d;
        await projectService.getProjectById(historyProjectId).then(res => {
            if (res.projectModelImages.length && project.id == 0) {
                setProject3d(false);
                is3d = false;
            }
            setProject(res);
            setFormJson(res.formJSON ? JSON.parse(res.formJSON) : null);
            if (res.projectModelImages.length > 0 && !is3d) {
                fetchProjectPinsForAProjectAndImageId(res, res.projectModelImages[0].id);
                setCurrentImageIndex(res.projectModelImages[0].id);
            }
            projectTypeService.getProjectTypeMetaDataFields(res.projectTypeId).then(metaDataRes => {
                setMetaDataFields(metaDataRes);

                // Initialize answers with empty strings or existing answers
                const initialAnswers = metaDataRes.reduce((acc, field) => {
                    const existingAnswer = res.metaDataAnswers?.find(a => a.projectTypeMetaDataFieldId === field.id);
                    acc[field.id] = existingAnswer ? existingAnswer.answer : '';
                    return acc;
                }, {});
                setMetaDataAnswers(initialAnswers);
            });
            setProjectType(res.projectType);
            setPointCategoryList(res.projectType.pointCategories);
            setProjectTypeCustomFieldList(res.projectType.projectTypeCustomFields);
            setPoiStatusList(res.projectType.projectTypePOIStatuses);
            fetchTierForUser(res, is3d);
            copyProjectTypeCustomFieldToProjectPinCustomField(res.projectType.projectTypeCustomFields);
            copyProjectTypeToProjectPinCheckBoxes(res.projectType.projectTypeCheckBoxes);
        })
    }

    const onChangeHandler = annotation => {
        if (auth.role !== 'Viewer') {
            setAnnotation(annotation);
        }
    };

    const fetchProjectPinsForAProjectAndImageId = useCallback(async (project, id) => {
        await projectPinService.getProjectPinsByProjectIdAndImageId(project.id, id ? id : '').then(res => {
            register2DProject(res, id);
        });
    })

    const handleCheckboxChange = (e, index, option) => {
        const updatedFields = [...filteredProjectPinCustomFields];
        let selectedOptions = updatedFields[index].description ? updatedFields[index].description.split(', ') : [];

        if (e.target.checked) {
            selectedOptions = [...selectedOptions, option];
        } else {
            selectedOptions = selectedOptions.filter(item => item !== option);
        }

        updatedFields[index].description = selectedOptions.join(', ');
        // setCustomFields(updatedFields);
        setFilteredProjectPinCustomFields(updatedFields);
    };


    const handleDescriptionChange = (e, index) => {
        const updatedFields = [...filteredProjectPinCustomFields];
        const field = updatedFields[index];

        if (field.customFieldType.toLowerCase() === 'dropdown') {
            field.description = e.target.value;
        } else if (field.customFieldType.toLowerCase() === 'multiselect') {
            field.description = e.target.value.join(', ');
        } else {
            field.description = e.target.value;
        }

        // setCustomFields(updatedFields);
        setFilteredProjectPinCustomFields(updatedFields);
    };


    // const renderData = (data) => {
    //     return (
    //         <div
    //             style={{
    //                 position: "absolute",
    //                 left: `${data.annotation.geometry.x}%`,
    //                 top: `${data.annotation.geometry.y}%`,
    //                 width: `${data.annotation.geometry.width}%`,
    //                 height: `${data.annotation.geometry.height}%`,
    //                 backgroundColor: "rgba(255, 255, 255, 0.5)",
    //                 border: "1px solid black",
    //                 display: "block",
    //             }}
    //             onMouseEnter={handleMouseEnter}
    //             onMouseLeave={handleMouseLeave}
    //         >
    //             {data.annotation.data}
    //         </div>
    //     );
    // };

    const fetchTierForUser = (proj, is3d) => {
        let tierId = proj.client.clientTier.id;
        let isTier2 = false;
        let isTier3 = false;
        if (tierId) {
            clientTierService.getClientTierById(tierId).then(res => {
                setTier(res);
                return { tier: res, proj: proj };
            }).then(tierAndProj => {
                isTier2 = checkTierPlanAndRoleTier2(tierAndProj.tier, auth.role);
                isTier3 = checkTierPlanAndRoleTier3(tierAndProj.tier, auth.role);
                if (isTier3) {
                    setIsBusinessTier(isTier3);
                }
                if (isTier2) {
                    setIsBusinessOrProfessionalTier(isTier2);
                }
            }).finally(() => {
                if (is3d) {
                    fetchProjectPinsForAProject(proj, isTier3);
                }
            }
            );
        }
        else {
            if (auth.role === 'SuperAdmin')
                if (is3d) {
                    fetchProjectPinsForAProject(proj, true); //superadmin
                }
        }
    }

    const fetchProjectPinsForAProject = (proj, isBusinessOrProfessional) => {
        projectPinService.getProjectPinsByProjectId(proj.id).then(res => {
            registerMatterPort(res, isBusinessOrProfessional);
        })
    }

    const register2DProject = (pins, id) => {
        let tempAnnotations = [];
        let pinsFiltered = [];
        if (!id) {
            pinsFiltered = pins.filter(x => x.projectModelImageId == currentImageIndex);
        }
        else {
            pinsFiltered = pins;
        }
        for (const pin of pinsFiltered) {
            tempAnnotations.push(JSON.parse(pin.annotationCoordinates));
        }
        setAnnotations(tempAnnotations);
        setSavedProjectPins(pins);
        setIsTableLoading(false);
    }

    const setProjectPinToEdit = (id) => {
        setEditProjectPinId(id);
        setUpdateProjectPinModalVisible(true);
    }

    const copyProjectTypeToProjectPinCheckBoxes = (projTypeCheckBoxes) => {
        let tempArr = [...projTypeCheckBoxes];
        tempArr = tempArr.map(x => {
            delete x['id'];
            delete x['projectTypeId'];
            return { ...x, isChecked: false }
        });

        setProjectPinCheckBoxes(tempArr);
    }

    const copyProjectTypeCustomFieldToProjectPinCustomField = (projTypeCustomFields) => {
        let tempArr = projTypeCustomFields.map(x => {
            const clonedObj = JSON.parse(JSON.stringify(x));
            var projectTypeCustomFieldId = clonedObj['id'];
            delete clonedObj['id'];
            delete clonedObj['projectTypeId'];
            return { ...clonedObj, projectTypeCustomFieldId: projectTypeCustomFieldId };
        });
        setCustomFields(tempArr);
    }

    // const handleDeleteCustomField = (id) => {
    //     setCustomFieldsData(prevData => prevData.filter(field => field.id !== id));
    // };

    const handleCustomFieldSelectChange = (event) => {
        setSelectedProjectTypeCustomField(event.target.value);
    };

    const handleCustomFieldDescriptionChange = (event) => {
        setCustomFieldDescription(event.target.value);
    };

    // const handleEditCustomField = (value) => {
    //     setSelectedProjectTypeCustomField(value.id);
    //     setEditedCustomField(value)
    //     setCustomFieldDescription(value.description);
    //     setIsModalOpen(true);
    // };


    const renderAnnotations = (image, index) => {
        if (currentImageIndex !== index) return null;
        if (annotations.length > 0) {
            const renderSelector = ({ annotation }) => {
                if (!annotation.geometry) {
                    return null;
                }
                return (
                    <div
                        style={{
                            position: "absolute",
                            left: `${annotation.geometry.x}%`,
                            top: `${annotation.geometry.y}%`,
                            width: `${annotation.geometry.width}%`,
                            height: `${annotation.geometry.height}%`,
                            backgroundColor: "rgba(255, 255, 255, 0.5)", // Make the annotation area lighter
                            mixBlendMode: "screen", // Use mix-blend-mode to blend the selector with the overlay
                            border: "1px solid black",
                            display: "block"
                        }}
                    />
                );
            };
            return (
                <>
                    <Annotation
                        annotations={annotations}
                        src={image}
                        ref={annotationRef}
                        type={RectangleSelector.TYPE}
                        value={annotation}
                        disableEditor
                        onChange={onChangeHandler}
                        style={{ height: '600px' }}
                        renderContent={({ handleMouseEnter, annotation }) => {
                            return (
                                <div>
                                    <div
                                        id='my-tooltip'
                                        data-tooltip-content={annotation.data.text}
                                        style={{
                                            position: "absolute",
                                            left: `${annotation.geometry.x}%`,
                                            top: `${annotation.geometry.y}%`,
                                            width: `${annotation.geometry.width}%`,
                                            height: `${annotation.geometry.height}%`,
                                            backgroundColor: "rgba(255, 255, 255, 0.6)",
                                            color: 'black',
                                            border: "1px solid black",
                                            display: "block"
                                        }}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={() => {
                                            if (highlightedAnnotationIndex) {
                                                removeSelectedMatterTag()
                                            }
                                        }}
                                    >
                                        <span>Hover for details</span>
                                    </div>
                                    <Tooltip anchorId='my-tooltip' />
                                </div>
                            )
                        }}
                        renderSelector={renderSelector}
                    />
                </>

            )
        }
        else {
            return (
                <Annotation
                    annotations={annotations}
                    src={image}
                    ref={annotationRef}
                    style={{ height: '600px' }}
                    type={RectangleSelector.TYPE}
                    value={annotation}
                    disableEditor
                    onChange={onChangeHandler}
                />
            )
        }

    };

    const hexToRgb = (hex) => {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: Math.round(parseInt(result[1], 16) / 255 * 10) / 10,
            g: Math.round(parseInt(result[2], 16) / 255 * 10) / 10,
            b: Math.round(parseInt(result[3], 16) / 255 * 10) / 10
        } : null;
    }

    const handleGotopoint = (annotationCoordinates, imageIndexId) => {
        const { id } = annotationCoordinates.data;
        const index = project.projectModelImages.findIndex(x => x.id == imageIndexId);
        setCurrentImageIndex(imageIndexId);
        setHighlightedAnnotationIndex(id);
        setCarouselIndex(index);
        setAnnotation(annotationCoordinates);
        scrollToTop();
    };

    const addProjectPinsToMatterPort = (sdk, pins, checkTier3) => {
        console.log('here are my pins', pins);
        pins = pins.filter(x => x.matterPointCoordinates !== null);
        let allMatterTags = pins.map(pin => {
            let coordinate = JSON.parse(pin.matterPointCoordinates);
            if (coordinate) {
                return {
                    label: pin.title,
                    description: pin.description,
                    ...(checkTier3 && pin.poiColorCode && {
                        color: hexToRgb(pin.poiColorCode)
                    }),
                    anchorPosition: coordinate.position,
                    stemVector: coordinate.normal,
                    stemVisible: true
                }
            }
        })

        console.log("Loading matter tags ", allMatterTags)
        // sdk.Mattertag.add(allMatterTags).then(function (mattertagIds) {
        //     console.log('ids', mattertagIds)
        //     for (let i = 0; i < mattertagIds.length; i++) {
        //         pins[i].key = mattertagIds[i];
        //     }
        //     setSavedProjectPins(pins);
        //     setIsTableLoading(false);
        // })
        //     .catch(ex => console.log("Exception is ", ex))
        //     .finally(x => {
        //         setIsTableLoading(false);
        //     })
        // console.log(sdk.Tag);
        for (let i = 0; i < allMatterTags.length; i++) {
            sdk.Tag.add(allMatterTags[i]).then(function (mattertagIds) {
                pins[i].key = mattertagIds;
            }
            )
                .catch(ex => console.log("Exception is:", ex))
        }
        setSavedProjectPins(pins);
        setIsTableLoading(false);
    }

    const registerMatterPort = (pins, isBusiness) => {
        console.log('matterport', iFrameRef.current)
        window.MP_SDK.connect(iFrameRef.current, constants.MATTERPORT_API_KEY, constants.MATTERPORT_VERSION).then((sdk) => {
            console.log('here is sdk', sdk);
            console.log('Matterport connected!');
            setGlobalSdk(sdk);

            addProjectPinsToMatterPort(sdk, pins, isBusiness);

            //toggle between Intersection Inspector and TransientTagEditor mode
            !useTransientTagEditor ?
                handleIntersectionInspector(sdk, !useTransientTagEditor) : handleTransientTagEditor(sdk, useTransientTagEditor);

        });
    }

    const handleTransientTagEditor = (mpSdk, condition) => {
        if (!condition) {
            let tag;
            let intersection;
            const addTag = () => {
                if (!tag) {
                    mpSdk.Tag.add({
                        anchorPosition: { x: 0, y: 0, z: 0 },
                        stemVector: { x: 0, y: 0, z: 0 }
                    }).then(key => {
                        tag = key;
                    })
                        .catch((e) => {
                            console.error(e);
                        })
                }
            }

            addTag()

            const placeTag = (intersectionData) => {
                if (tag) {
                    mpSdk.Mattertag.navigateToTag(tag[0], mpSdk.Mattertag.Transition.FLY);
                    setSelectedPoint({ ...intersectionData, stemVisible: true, key: tag });
                }
                tag = null;
            }

            const focusDetect = () => {
                const eventListener = window.addEventListener('blur', () => {
                    if (document.activeElement === iFrameRef.current) {
                        placeTag(intersection); //function you want to call on click
                        setTimeout(function () { window.focus(); }, 0);
                    }
                    window.removeEventListener('blur', eventListener);
                });
            }

            focusDetect();


            const updateTagPos = (newPos, newNorm = undefined, scale = undefined) => {

                if (!newPos) return;
                if (!scale) scale = .33;
                if (!newNorm) newNorm = { x: 0, y: 1, z: 0 };

                mpSdk.Mattertag.editPosition(tag[0], {
                    anchorPosition: newPos,
                    stemVector: {
                        x: scale * newNorm.x,
                        y: scale * newNorm.y,
                        z: scale * newNorm.z,
                    }
                })
                    .catch(e => {
                        console.error(e);
                        tag = null;
                        setSelectedPoint(null);
                    });
            }

            mpSdk.Pointer.intersection.subscribe(intersectionData => {
                if (tag) {
                    if (intersectionData.object === 'intersectedobject.model' || intersectionData.object === 'intersectedobject.sweep') {
                        intersection = intersectionData;
                        updateTagPos(intersectionData.position, intersectionData.normal);
                    }
                }
            });
        }

    }
    const handleInspectorConditions = (condition) => {
        handleTransientTagEditor(globalSdk, condition);
    }

    const handleToggleInspectionModel = () => {
        setUseTransientTagEditor(!useTransientTagEditor);

        if (useTransientTagEditor) {

            handleIntersectionInspector(globalSdk, true);
            handleTransientTagEditor(globalSdk, useTransientTagEditor);
        }
        else {

            setDisplayBtn(false);
            handleIntersectionInspector(globalSdk, false);
            handleTransientTagEditor(globalSdk, !useTransientTagEditor);
        }
    }

    const stopCalculationAndSetPoint = () => {
        setDisplayBtn(false);
        buttonDisplayed = false;
        let sliderDecimalFixedValue = parseFloat(intersectionState.normal.y.toFixed(6));
        setSlider(sliderDecimalFixedValue);
        globalSdk.Tag.add({
            label: '',
            anchorPosition: intersectionState.position,
            stemVector: { ...intersectionState.normal, y: sliderDecimalFixedValue },
            stemVisible: true
        }).then((key) => {
            setSelectedPoint({ ...intersectionState, normal: { ...intersectionState.normal, y: sliderDecimalFixedValue }, key: key, stemVisible: true });
        })
    }

    const handleIntersectionInspector = (sdk, isIntersectionInspectorTrueDuringToggle) => {
        if (auth.role !== 'Viewer') {
            if (isIntersectionInspectorTrueDuringToggle) {
                sdk.Camera.pose.subscribe(function (pose) {
                    poseCache = pose;
                });

                sdk.Pointer.intersection.subscribe(function (intersection) {
                    intersectionCache = intersection;
                    intersectionCache.time = new Date().getTime();
                    setDisplayBtn(false);
                    buttonDisplayed = false;
                });

                buttonDisplayed = false;

                let myIntervalId = setInterval(() => {

                    if (!intersectionCache || !poseCache) {
                        return;
                    }
                    const nextShow = intersectionCache.time + delayBeforeShow;
                    if (new Date().getTime() > nextShow) {
                        if (buttonDisplayed)
                            return;

                        var size = {
                            w: iFrameRef.current ? iFrameRef.current.clientWidth : 0,
                            h: iFrameRef.current ? iFrameRef.current.clientHeight : 0
                        }
                        var coord = sdk.Conversion.worldToScreen(intersectionCache.position, poseCache, size);
                        setDisplayBtn(true);
                        buttonDisplayed = true;
                        setBtnPosition({ left: `${coord.x - 30}px`, top: `${coord.y + 100}px` });
                        setIntersectionState(intersectionCache);
                    }
                }, 100);
                setMyIntervalId(myIntervalId);
            }
            else {
                clearInterval(myIntervalId);
            }
        }
    }

    const navigateToPoint = (id) => {
        return globalSdk.Mattertag.navigateToTag(id, globalSdk.Mattertag.Transition.FLY);
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "auto"
        })
    }

    const deletePoint = (id, key) => {
        projectPinService.deleteProjectPin(id, true).then(res => {
            if (globalSdk.Tag) {
                globalSdk.Tag.remove(key[0]);
            }
            let tempList = [...savedProjectPins];
            setSavedProjectPins(tempList.filter(x => x.id !== id));
            showAlert({ message: res.message, variant: 'success' });
        }).catch(ex => {
            showAlert({ message: ex.message, variant: 'error' })
        }).finally(() => hideConfirmModal())
    }

    const deleteAnnotationPoints = (id) => {
        projectPinService.deleteProjectPin(id, true).then(res => {
            if (Object.keys(annotations).length > 0) {
                setAnnotations(annotations.filter(x => x.data.id == id));
                setFetchNewAnnotation(!fetchNewAnnotation);
            }
            let tempList = [...savedProjectPins];
            setSavedProjectPins(tempList.filter(x => x.id !== id));
            showAlert({ message: res.message, variant: 'success' });
        }).catch(ex => {
            showAlert({ message: ex.message, variant: 'error' })
        }).finally(() => hideConfirmModal())
    }

    const handlePointCategoryChange = (selectedPointCategoryId) => {
        // Get associated ProjectTypeCustomField IDs for the selected PointCategory
        const associatedCustomFieldIds = projectTypeCustomFieldList
            .filter(field => field.pointCategoryProjectTypeCustomFields
                .some(assoc => assoc.pointCategoryId === selectedPointCategoryId))
            .map(field => field.id);

        // Filter ProjectPinCustomFields based on the associated ProjectTypeCustomFields
        const filteredFields = customFields
            .filter(field => associatedCustomFieldIds.includes(field.projectTypeCustomFieldId));

        setFilteredProjectPinCustomFields(filteredFields);
    };

    const renderTabContent = () => {
        switch (activeTab) {
            case 'inspectionPoints':
                return renderInspectionPointsTab();
            case 'inspectionData':
                return renderInspectionPropertiesTab();
            case 'documents':
                return renderDocumentsTab();
            default:
                return null;
        }
    };

    const columns = [
        {
            name: "title",
            label: "Title",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
            },
        },
        {
            name: "pointCategory",
            label: "N-Point Category",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <p>{savedProjectPins[dataIndex].pointCategory}</p>
                    )
                }
            },
        },
        {
            name: "projectTypePOIStatus",
            label: "N-Point Status",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <p>{savedProjectPins[dataIndex].projectTypePOIStatus ? savedProjectPins[dataIndex].projectTypePOIStatus : '-'}</p>
                    )
                }
            },
        },
        ...(isBusinessTier ?
            [{
                name: "isResolved",
                label: "Resolved",
                options: {
                    filter: true,
                    sort: false,
                    width: 1,
                    download: true,
                    customBodyRenderLite: (dataIndex) => {
                        return (
                            <SVG
                                className="h-50 align-self-center"
                                src={toAbsoluteUrl(savedProjectPins[dataIndex].isResolved ? "/media/svg/icons/Navigation/Check.svg" : "/media/svg/icons/Navigation/Close.svg")}
                            >
                            </SVG>
                        );
                    }
                },
            }] : []),
        ...(isBusinessTier ?
            [{
                name: "isApproved",
                label: "Approved",
                options: {
                    filter: true,
                    sort: false,
                    width: 1,
                    download: true,
                    customBodyRenderLite: (dataIndex) => {
                        return (
                            <SVG
                                className="h-50 align-self-center"
                                src={toAbsoluteUrl(savedProjectPins[dataIndex].isApproved ? "/media/svg/icons/Navigation/Check.svg" : "/media/svg/icons/Navigation/Close.svg")}
                            >
                            </SVG>
                        );
                    }
                },
            }]
            :
            []),
        {
            name: "addedBy",
            label: "Added By",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
            },
        },
        {
            name: "updatedOn",
            label: "Updated On",
            options: {
                filter: false,
                sort: true,
                width: 1,
                download: true,
            },
        },
        {
            name: "projectPinCheckBoxes",
            label: "CheckBoxes",
            options: {
                filter: false,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            {savedProjectPins[dataIndex].projectPinCheckBoxes?.length > 0 ? savedProjectPins[dataIndex].projectPinCheckBoxes?.map((x, i) =>
                                <span key={i} className="label label-lg c-white bg-blue label-inline font-weight-bold py-4 mr-3 mt-2 mb-2" style={{
                                    whiteSpace: 'nowrap'
                                }}>
                                    {x}
                                </span>
                            ) : "-"}
                        </>
                    )
                }
            },
        },
        {
            name: "projectPinCustomFields",
            label: "Custom Fields",
            options: {
                filter: false,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            {savedProjectPins[dataIndex].projectPinCustomFields?.length > 0 ? savedProjectPins[dataIndex].projectPinCustomFields?.map((x, i) => (
                                x.description && (
                                    <span key={i} className="label label-lg c-white bg-blue label-inline font-weight-bold py-4 mr-3 mt-2 mb-2" style={{
                                        whiteSpace: 'nowrap'
                                    }}>
                                        {x.name}
                                    </span>
                                )
                            )) : ""}

                        </>
                    )
                }
            },
        },
        {
            name: "id",
            label: "Actions",
            options: {
                filter: false,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            {
                                project3d &&
                                <a
                                    className="btn btn-info btn-sm mr-3"
                                    onClick={() => {
                                        navigateToPoint(savedProjectPins[dataIndex].key[0]);
                                        scrollToTop();
                                    }}
                                >
                                    Go To Point
                                </a>
                            }

                            {
                                !project3d && project.projectModelImages && project.projectModelImages.length > 0 &&
                                <a
                                    className="btn btn-info btn-sm mr-3"
                                    onClick={() => {
                                        handleGotopoint(JSON.parse(savedProjectPins[dataIndex].annotationCoordinates), savedProjectPins[dataIndex].projectModelImageId);
                                    }}
                                >
                                    Go To Point
                                </a>

                            }
                            <button
                                className="btn btn-info btn-hover-info btn-sm mr-4 mt-2 mb-2"
                                onClick={() => {
                                    setProjectPinToEdit(savedProjectPins[dataIndex].id);
                                    setPoiStatusName(savedProjectPins[dataIndex].projectTypePOIStatus);
                                }
                                }
                            >
                                Edit
                            </button>
                            {

                                project.completedDateTime ? <></> :
                                    (auth.role === 'Admin' || auth.role === 'SuperAdmin' || auth.role === 'Manager') &&
                                    <a
                                        className="btn btn-danger btn-sm mr-3"
                                        onClick={() => showConfirmModal({
                                            title: "Delete Pin",
                                            text: "Are you sure you want to delete this pin?",
                                            rightBtnText: "Confirm",
                                            btnAction: () => {
                                                if (project3d) {
                                                    return deletePoint(savedProjectPins[dataIndex].id, savedProjectPins[dataIndex].key);
                                                }
                                                return deleteAnnotationPoints(savedProjectPins[dataIndex].id);
                                            }
                                        })}
                                    >
                                        Delete Point
                                    </a>
                            }
                        </>
                    );
                }
            }
        }
    ];

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: projectPin,
        validationSchema: ProjectPinValidator,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setFormSubmit(true);
            setChecked(false);
            await savePoint(values, setSubmitting);
        }
    });

    const captureAnnotatedImage = (src, geometry, values, setSubmitting) => {
        // src = 'http://192.168.1.197:45455/Attachments/Projects/93d81b37-f5db-4c5a-86c7-179453c8bde2.jpg';
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();
        img.crossOrigin = 'anonymous'; // allow CORS headers
        img.src = src;

        canvas.style.position = 'absolute';
        canvas.style.top = '0';
        canvas.style.left = '0';

        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const multByX = img.width / 100;
            const multByY = img.height / 100;

            const { x, y, width, height } = geometry;
            // draw the rectangle
            ctx.beginPath();
            ctx.lineWidth = '10';
            ctx.strokeStyle = 'red';
            ctx.rect(x * multByX, y * multByY, width * multByX, height * multByY);
            ctx.stroke();

            const base64 = canvas.toDataURL('image/jpeg', 0.3);

            postImageWithPin(values, base64, setSubmitting);
        };
    };

    const savePoint = async (values, setSubmitting) => {
        setIsTableLoading(true);
        var imageString = "";
        if (selectedPoint) {
            await navigateToPoint(selectedPoint.key[0]);
            await globalSdk.Camera.takeScreenShot({ width: 632, height: 374 }, {
                mattertags: true,
                sweeps: true,
            }).then(res => {
                imageString = res.split(',').length > 0 ? res.split(',')[1] : '';
                setImageString(imageString);
            });

            await projectPinService.saveProjectPin({
                ...values,
                MatterPointCoordinates: JSON.stringify(selectedPoint),
                imageUrl: imageString,
                projectPinCheckBoxes: projectPinCheckBoxes,
                projectPinCustomFields: filteredProjectPinCustomFields
            })
                .then(async res => {
                    // Create an array of custom field names
                    const customFieldNames = filteredProjectPinCustomFields
                        .filter(field => projectTypeCustomFieldList.some(data => data.name === field.name));
                    let newPointToAdd = {
                        ...res.projectPin,
                        key: selectedPoint.key,
                        projectPinCheckBoxes: projectPinCheckBoxes && projectPinCheckBoxes.length
                            ? projectPinCheckBoxes.filter(x => x.isChecked === true).map(x => x.name)
                            : [],
                        updatedOn: moment().format('MM/DD/yyyy hh:mm a'),
                        pointCategory: pointCategoryList && pointCategoryList.length && pointCategoryList.find(x => x.id === values.pointCategoryId)
                            ? pointCategoryList.find(x => x.id === values.pointCategoryId).name
                            : null,
                        projectTypePOIStatus: poiStatusList && poiStatusList.length && poiStatusList.find(x => x.id === values.projectTypePOIStatusId)
                            ? poiStatusList.find(x => x.id === values.projectTypePOIStatusId).name
                            : null,
                        addedBy: `${auth.user.firstName} ${auth.user.lastName}`,
                        projectPinCustomFields: customFieldNames && customFieldNames.length ? customFieldNames : []
                    };

                    let tempList = [...savedProjectPins]
                    tempList.push(newPointToAdd);
                    //Edit Mattertags
                    editMatterportBillboard(values);
                    editMattertagColor(values);
                    setSavedProjectPins(tempList);
                    showAlert({ message: res.message, variant: 'success' });
                    setIsTableLoading(false);
                }).catch(ex => {
                    showAlert({ message: ex.message, variant: 'error' });
                }).finally(() => {
                    setSelectedPoint(null);
                    formik.resetForm();
                    setSelectedCustomFieldIds([]);
                    let temp = [...projectPinCheckBoxes];
                    setProjectPinCheckBoxes(temp.map(x => { return { ...x, isChecked: false } }));
                    setSubmitting(false);
                    setSlider(1);
                    // setCustomFieldsData([]);
                    copyProjectTypeCustomFieldToProjectPinCustomField(projectTypeCustomFieldList)
                });
        }
        else {
            var currentImage = project.projectModelImages.find(x => x.id == currentImageIndex);
            var imageUrl = captureAnnotatedImage(constants.API_URL + 'api/' + currentImage.imagePath, annotation.geometry, values, setSubmitting);
        }
    }

    const postImageWithPin = async (values, imageUrl, setSubmitting) => {
        await projectPinService.saveProjectPin({
            ...values,
            AnnotationCoordinates: JSON.stringify({ ...annotation, data: { ...annotation.data, id: Math.random(), text: `${values.title} : ${values.description}` } }),
            projectModelImageId: currentImageIndex,
            // imageUrl: currentImage && currentImage.imagePath,
            imageUrl,
            projectPinCheckBoxes: projectPinCheckBoxes,
            projectPinCustomFields: filteredProjectPinCustomFields
        })
            .then(async res => {
                // Create an array of custom field names
                const customFieldNames = filteredProjectPinCustomFields
                    .filter(field => projectTypeCustomFieldList.some(data => data.name === field.name));
                let newPointToAdd = {
                    ...res.projectPin,
                    key: res.projectPin.annotationCoordinates ? JSON.parse(res.projectPin.annotationCoordinates).data?.id : null,
                    projectPinCheckBoxes: projectPinCheckBoxes && projectPinCheckBoxes.length
                        ? projectPinCheckBoxes.filter(x => x.isChecked === true).map(x => x.name)
                        : [],
                    updatedOn: moment().format('MM/DD/yyyy hh:mm a'),
                    pointCategory: pointCategoryList && pointCategoryList.length && pointCategoryList.find(x => x.id === values.pointCategoryId)
                        ? pointCategoryList.find(x => x.id === values.pointCategoryId).name
                        : null,
                    projectTypePOIStatus: poiStatusList && poiStatusList.length && poiStatusList.find(x => x.id === values.projectTypePOIStatusId)
                        ? poiStatusList.find(x => x.id === values.projectTypePOIStatusId).name
                        : null,
                    addedBy: `${auth.user.firstName} ${auth.user.lastName}`,
                    projectPinCustomFields: customFieldNames && customFieldNames.length ? customFieldNames : []
                };
                let tempList = [...savedProjectPins]
                tempList.push(newPointToAdd);
                setSavedProjectPins(tempList);
                setAnnotations([...annotations, JSON.parse(res.projectPin.annotationCoordinates)])
                showAlert({ message: res.message, variant: 'success' });
                setIsTableLoading(false);
            }).catch(ex => {
                showAlert({ message: ex.message, variant: 'error' });
            }).finally(() => {
                formik.resetForm();
                setSelectedCustomFieldIds([]);
                let temp = [...projectPinCheckBoxes];
                setProjectPinCheckBoxes(temp.map(x => { return { ...x, isChecked: false } }));
                setSubmitting(false);
                setAnnotation({});
                // setCustomFieldsData([]);
                copyProjectTypeCustomFieldToProjectPinCustomField(projectTypeCustomFieldList)
            });
    }

    // const handleSaveCustomField = () => {
    //     const selectedField = projectTypeCustomFieldList.find(field => field.id === selectedProjectTypeCustomField);

    //     const newCustomFieldData = {
    //         id: selectedField.id,
    //         name: selectedField.name,
    //         isViewable: selectedField.isViewable,
    //         isEditable: selectedField.isEditable,
    //         description: customFieldDescription,
    //         customFieldTypeOptions: selectedField.customFieldTypeOptions
    //     };

    //     setCustomFieldsData(prevData => [...prevData, newCustomFieldData]);
    //     setIsModalOpen(false);
    // };


    // const handleSaveEditedCustomField = () => {
    //     const editedFieldIndex = customFieldsData.findIndex(field => field.id === editedCustomField.id);
    //     if (editedFieldIndex !== -1) {
    //         const updatedCustomField = {
    //             ...editedCustomField,
    //             description: customFieldDescription
    //         };

    //         setCustomFieldsData(prevData => [
    //             ...prevData.slice(0, editedFieldIndex),
    //             updatedCustomField,
    //             ...prevData.slice(editedFieldIndex + 1)
    //         ]);

    //     }

    //     setEditedCustomField(null);
    //     setIsModalOpen(false);
    // };



    const removeSelectedMatterTag = () => {
        formik.resetForm();
        setSelectedCustomFieldIds([]);
        if (selectedPoint) {
            globalSdk.Tag.remove(selectedPoint.key[0]).then(res => {
                setSelectedPoint(null);
                setSlider(1);
            })
        }
        setAnnotation({});
        setHighlightedAnnotationIndex(null);
        setSelectedProjectTypeCustomField('');
        setSelectedProjectTypeCustomFields([]);
        let temp = [...projectPinCheckBoxes];
        setProjectPinCheckBoxes(temp.map(x => { return { ...x, isChecked: false } }));
        setCustomFieldDescription('');
        setCustomFieldsData([]);
        copyProjectTypeCustomFieldToProjectPinCustomField(projectTypeCustomFieldList)
    }

    const editMattertagColor = async (value) => {
        let colorCode;
        if (isBusinessTier && poiStatusList && poiStatusList.length) {
            const foundStatus = poiStatusList.find(x => x.id === value.projectTypePOIStatusId);
            colorCode = foundStatus ? foundStatus.poiColorCode : null;
        }

        try {
            await globalSdk.Tag.editColor(selectedPoint.key[0], {
                ...(isBusinessTier && colorCode && { ...hexToRgb(colorCode) }),
            });
        } catch (ex) {
            console.log("Ex is ", ex);
        }
    }

    const editMatterportBillboard = async (value) => {
        // await globalSdk.Mattertag.editBillboard(selectedPoint.key[0], {
        //     description: value.description,
        //     label: value.title
        // }).catch(ex => console.log("Ex is ", ex));
        await globalSdk.Tag.editBillboard(selectedPoint.key[0], {
            media: {
                type: globalSdk.Mattertag.MediaType.PHOTO,
                src: value.imageSrc
            },
            description: value.description,
            label: value.title
        }).catch(ex => console.log("Ex is ", ex));
    }

    const hasCustomFieldErrors = () => {
        for (let index = 0; index < filteredProjectPinCustomFields.length; index++) {
            const field = filteredProjectPinCustomFields[index];
            if (validateDescription(field.description, field.customFieldType, field.options)) {
                return true;
            }
        }
        return false;
    };

    const removeAndChangeStemLength = (val) => {
        globalSdk.Tag.remove(selectedPoint.key[0]).then(res => {
            setSelectedPoint(null);
            globalSdk.Tag.add({
                label: '',
                anchorPosition: intersectionState.position,
                stemVector: { ...intersectionState.normal, y: val },
                stemVisible: true
            }).then((key) => {
                setSelectedPoint({ ...intersectionState, normal: { ...intersectionState.normal, y: val }, key: key, stemVisible: true });
            })
        })
    }

    const removeAndChangeStemLengthTransientEditor = (val) => {
        globalSdk.Tag.remove(selectedPoint.key[0]).then(res => {
            setSelectedPoint(null);
            globalSdk.Tag.add({
                label: '',
                anchorPosition: selectedPoint.position,
                stemVector: { ...selectedPoint.normal, y: val },
                stemVisible: true
            }).then((key) => {
                setSelectedPoint({ ...selectedPoint, normal: { ...selectedPoint.normal, y: val }, key: key, stemVisible: true });
            })
        })
    }

    const markProjectAsComplete = () => {
        projectService.completeProject(project.id).then(res => {
            setProject({ ...project, completedDateTime: new Date() })
            showAlert({ message: res.message, variant: 'success' });
        }).catch(ex => {
            showAlert({ message: ex.message, variant: 'error' });
        }).finally(() => hideConfirmModal())
    }

    const handleMetaDataAnswerChange = (fieldId, value) => {
        setMetaDataAnswers(prevAnswers => ({
            ...prevAnswers,
            [fieldId]: value
        }));
    };

    const saveMetaDataAnswers = () => {
        setIsSavingAnswers(true);
        const answersArray = Object.entries(metaDataAnswers).map(([id, answer]) => ({
            projectTypeMetaDataFieldId: parseInt(id),
            answer
        }));

        projectService.saveProjectMetaDataAnswers(project.id, answersArray)
            .then(res => {
                showAlert({ message: res.message, variant: "success" });
            })
            .catch(err => {
                showAlert({ message: err.message, variant: "error" });
            }).finally(() => {
                setIsSavingAnswers(false);
            });
    };

    const renderInspectionPointsTab = () => {
        return (
            <div className="card card-custom">
                <div className="card-body">
                    {
                        !project3d && project && project.projectModelImages && project.projectModelImages.length > 0 &&
                        <FormControlLabel
                            control={
                                <BlueCheckBox
                                    checked={showAllChecked}
                                    onChange={(e, val) => {
                                        if (val) {
                                            fetchProjectPinsForAProjectAndImageId(project, '');
                                        }
                                        else {
                                            setFetchNewAnnotation(!fetchNewAnnotation);
                                        }
                                        setShowAllChecked(val);
                                    }}
                                />
                            }
                            label={`Show All Points`}
                            labelPlacement="end"
                        />
                    }

                    <MUIDataTable
                        data={savedProjectPins}
                        columns={columns}
                        options={{
                            selectableRows: false
                        }}
                    />
                </div>
            </div>
        );
    };

    const renderDocumentsTab = () => {
        return (
            <ProjectDocumentPage
                id={id}
                showAlert={showAlert}
                showConfirmModal={showConfirmModal}
                hideConfirmModal={hideConfirmModal}
            />
        );
    };

    const renderInspectionPropertiesTab = () => {
        return renderMetaDataFields();
    };

    const renderMetaDataFields = () => {
        if (!project.projectType?.projectTypeMetaDataFields || project.projectType.projectTypeMetaDataFields.length === 0) {
            return (
                <div className="card card-custom">
                    <div className="card-body">
                        <p className="text-muted">No nspection questions are available for this nspection type.</p>
                    </div>
                </div>
            );
        }
        return (
            <div className="card card-custom">
                <div className="card-body" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                    {metaDataFields.map((field) => (
                        <div key={field.id} className="form-group">
                            <label>{field.question}</label>
                            <input
                                type="text"
                                className="form-control"
                                value={metaDataAnswers[field.id] || ''}
                                onChange={(e) => handleMetaDataAnswerChange(field.id, e.target.value)}
                            />
                        </div>
                    ))}
                </div>
                <div className="card-footer save-cancel-button-container">
                    <button
                        className="btn btn-success"
                        onClick={saveMetaDataAnswers}
                        disabled={isSavingAnswers}
                    >
                        {isSavingAnswers ? 'Saving...' : 'Save Answers'}
                        {isSavingAnswers && <span className="ml-2 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                    </button>
                </div>
            </div>
        );
    };

    return (
        <div className={`card card-custom card-stretch gutter-b`}>
            <div className="row project-edit-container p-5">
                <div className={`${project.completedDateTime ? "col-xl-12" : "col-xl-8"}`} style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="card card-custom bp-0" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <div className="card-header border-0 py-5">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label font-weight-bolder text-dark">
                                    {projectType.name}
                                    <br />
                                    {project.projectName}
                                    <br />
                                </span>
                                <div className="matterport-project-date-tags">
                                    <span className="label label-lg c-white bg-blue label-inline font-weight-bold py-4 mt-2">
                                        Scan Date: {moment(project.scanDate).format('MMMM Do, YYYY')}
                                    </span>
                                    {
                                        project.completedDateTime ?
                                            <span className="label label-lg c-white bg-green label-inline font-weight-bold py-4 mt-2">
                                                Completed On: {moment(project.completedDateTime).format('MMMM Do, YYYY')}
                                            </span> : <></>
                                    }
                                </div>
                            </h3>

                            {
                                isTableLoading &&
                                <div className="">
                                    <h3 style={{ fontSize: 20, fontWeight: 600 }}>
                                        Loading Table...
                                    </h3>
                                </div>
                            }
                            <ul className="nav nav-tabs nav-tabs-line">
                                {
                                    !(project.modelLink && project.projectModelImages && project.projectModelImages.length > 0) ?
                                        <>
                                            <li className="nav-item">
                                                <a className="nav-link disabled" data-toggle="tab" tabindex="-1" aria-disabled="true">2D Model</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link disabled" data-toggle="tab" tabindex="-1" aria-disabled="true">3D Model</a>
                                            </li>
                                        </>
                                        :
                                        <>
                                            <li className="nav-item">
                                                <a className={`nav-link ${!project3d ? `active` : ``}`} data-toggle="tab" onClick={() => {
                                                    setProject3d(false);
                                                    setCarouselIndex(0);
                                                    removeSelectedMatterTag()
                                                    setCurrentImageIndex('')
                                                    setShowAllChecked(false);
                                                    setProjectTypeChangeDetector(!projectTypeChangeDetector);
                                                }}>2D Model</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className={`nav-link ${project3d ? 'active' : ''} ${(!project.modelLink || project.modelLink.length === 0) ? 'disabled' : ''}`}
                                                    onClick={(e) => {
                                                        if ((!project.modelLink || project.modelLink.length === 0)) {
                                                            e.preventDefault();
                                                            return;
                                                        }
                                                        setProject3d(true);
                                                        setCarouselIndex(0);
                                                        setCurrentImageIndex('')
                                                        removeSelectedMatterTag()
                                                        setShowAllChecked(false);
                                                        setProjectTypeChangeDetector(!projectTypeChangeDetector);
                                                    }}>3D Model</a>
                                            </li>
                                        </>
                                }
                            </ul>

                            <div className="card-toolbar">
                                {
                                    (project && !project.completedDateTime && (auth.role === "SuperAdmin" || auth.role === "Admin" || auth.role === 'Manager' || auth.role === 'User')) ?
                                        <button
                                            onClick={() => setUpdateImagesModalVisible(true)}
                                            className="btn btn-success font-weight-bolder font-size-sm mr-3"
                                        >
                                            <span className="fa fa-plus"></span> Update Model
                                        </button>
                                        : <></>
                                }

                                <a
                                    className="btn btn-light btn-hover-warning btn-fixed-height font-weight-bold px-2 px-lg-5"
                                >
                                    <span className="svg-icon svg-icon-lg svg-icon-info">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/General/Clipboard.svg")} />
                                    </span>Total Issues: {savedProjectPins.length}</a>

                                {
                                    (auth.role === "SuperAdmin" || auth.role === "Admin" || auth.role === 'Manager' || auth.role === 'User') ?
                                        <UploadImagesModal
                                            show={updateImagesModalVisible}
                                            hide={() => setUpdateImagesModalVisible(false)}
                                            refresh={() => { setProjectTypeChangeDetector(!projectTypeChangeDetector) }}
                                            project={project}
                                        /> : <></>
                                }

                                {
                                    project.completedDateTime ?
                                        <Link
                                            to={{
                                                pathname: "/project/report/" + historyProjectId,
                                                search: "?id=" + historyProjectId,
                                            }}
                                            className="btn btn-info btn-fixed-height font-weight-bold px-2 px-lg-5 ml-3"
                                        >
                                            <span className="svg-icon svg-icon-lg svg-icon-white">
                                                <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Selected-file.svg")} />
                                            </span>Generate Report
                                        </Link> : <></>
                                }


                            </div>
                        </div>

                        <div className="card-body pt-0 pb-0" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                            <div className="matterport-model-container" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                {

                                    project3d && project.modelLink &&
                                    <div className="tab-content matterport-model-window" style={{ flex: 1 }}>

                                        <>
                                            <a
                                                style={{ left: btnPosition.left, top: btnPosition.top, display: displayBtn ? 'block' : 'none' }}
                                                id="iframe-btn"
                                                onClick={!useTransientTagEditor && stopCalculationAndSetPoint}
                                            // type="button"
                                            >
                                                <span className="svg-icon svg-icon-3x svg-icon-danger m-0">
                                                    <SVG
                                                        src={toAbsoluteUrl(
                                                            "/media/svg/icons/Map/Marker2.svg"
                                                        )}
                                                    />
                                                </span>
                                                {/* Add Marker */}
                                            </a>
                                            <iframe
                                                ref={iFrameRef}
                                                title='Matterport'
                                                src={`${project.modelLink}&hr=0&play=1&title=0&qs=1&applicationKey=${constants.MATTERPORT_API_KEY}`}
                                                id="showcase"
                                                className="matterport-iframe"
                                                frameBorder="0"
                                                allowFullScreen
                                                style={{ pointerEvents: selectedPoint || project.completedDateTime ? 'none' : 'auto', cursor: 'pointer' }}
                                                allow="xr-spatial-tracking"
                                            >
                                            </iframe>
                                        </>
                                    </div>
                                }
                                {
                                    !project3d && project.projectModelImages && project.projectModelImages.length > 0 &&
                                    <div className="tab-content matterport-model-window" style={{ flex: 1 }}>
                                        <div className='image-container-carousel'>
                                            <Carousel
                                                showArrows
                                                disabled
                                                defaultActiveIndex={0}
                                                selectedItem={carouselIndex}
                                                onChange={(newActiveIndex) => {
                                                    const currentImageId = project.projectModelImages[newActiveIndex].id;
                                                    setCurrentImageIndex(currentImageId);
                                                    setCarouselIndex(newActiveIndex)
                                                    setFetchNewAnnotation(!fetchNewAnnotation)
                                                }}
                                            >
                                                {project.projectModelImages.map((image, index) => (
                                                    <div key={index} style={{ position: 'relative', width: '100%', height: '100%' }}>
                                                        {renderAnnotations(
                                                            constants.API_URL + 'api/' + image.imagePath,
                                                            project.projectModelImages[index].id
                                                        )}
                                                    </div>
                                                ))}
                                            </Carousel>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                    {
                        project3d && (auth.role !== 'Viewer') &&
                        <div className="card py-10 px-5 mt-0 mr-3 h-10">
                            <div className="d-flex justify-content-between">
                                <button
                                    onClick={() => handleToggleInspectionModel()}
                                    className="btn btn-success btn-fixed-height font-weight-bold px-2 px-lg-5 d-flex justify-content-lg-start"
                                >Toggle Inspection Mode</button>

                                <button
                                    disabled={!useTransientTagEditor}
                                    onClick={() => handleInspectorConditions(false)}
                                    className="btn btn-success btn-fixed-height font-weight-bold px-2 px-lg-5 d-flex justify-content-lg-start"
                                >Add pointer</button>
                            </div>
                        </div>
                    }
                </div>


                <div className={`${project.completedDateTime ? "d-none" : "col-xl-4"}`} style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="card card-custom mb-lg-5" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <div className="card-header border-0 py-5 d-flex align-items-center">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label font-weight-bolder text-dark">
                                    Add N-Point {'  '}
                                    <span className={`label label-lg label-${(selectedPoint || (Object.keys(annotation).length !== 0 && !highlightedAnnotationIndex)) ? 'success' : 'light-secondary c-grey'} label-inline font-weight-bold py-4 mt-2`}>
                                        {(selectedPoint || (Object.keys(annotation).length !== 0 && !highlightedAnnotationIndex)) ? 'Active' : 'Inactive'}
                                    </span>
                                </span>
                            </h3>
                        </div>

                        <div className="card-body pt-0 pb-lg-18">
                            <div className={`tab-content matterport-point-window ${(selectedPoint || (Object.keys(annotation).length !== 0 && !highlightedAnnotationIndex)) ? '' : 'disabled-overlay'} `}>
                                <div className="add-point-container">

                                    <form onSubmit={formik.handleSubmit}>
                                        <FormControl variant="outlined" className={`col-lg-12 ${classes.formControl}`}>
                                            <InputLabel htmlFor="outlined-age-native-simple">N-Point Category</InputLabel>
                                            <Select
                                                error={getErrorField(formik, "pointCategoryId")}
                                                native
                                                label="N-Point Category"
                                                inputProps={{
                                                    name: 'category',
                                                    id: 'outlined-age-native-simple',
                                                }}
                                                {...formik.getFieldProps("pointCategoryId")}
                                                onChange={(e) => {
                                                    formik.setFieldValue("pointCategoryId", e.target.value === "" ? "" : Number(e.target.value));
                                                    handlePointCategoryChange(Number(e.target.value));
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                {
                                                    pointCategoryList.map(x => <option key={x.id} value={x.id}>{x.name}</option>)
                                                }
                                            </Select>
                                            {formik.touched.pointCategoryId && formik.errors.pointCategoryId ? (
                                                <div className="text-danger">
                                                    {formik.errors.pointCategoryId}
                                                </div>
                                            ) : null}
                                        </FormControl>

                                        &nbsp;
                                        <FormControl variant="outlined" className={`col-lg-12 ${classes.formControl}`}>
                                            <InputLabel htmlFor="outlined-age-native-simple">N-Point Status</InputLabel>
                                            <Select
                                                error={getErrorField(formik, "projectTypePOIStatusId")}
                                                native
                                                label="N-Point Status"
                                                inputProps={{
                                                    name: 'POI Status',
                                                    id: 'outlined-age-native-simple',
                                                }}
                                                {...formik.getFieldProps("projectTypePOIStatusId")}
                                                onChange={e => {
                                                    formik.setFieldValue("projectTypePOIStatusId", e.target.value === "" ? "" : Number(e.target.value));
                                                }}
                                            >
                                                <option aria-label="None" value="" onChange={(e) => console.log(e)} />
                                                {
                                                    poiStatusList.map(x => <option key={x.id} value={x.id}>{x.name}</option>)
                                                }
                                            </Select>
                                            {formik.touched.projectTypePOIStatusId && formik.errors.projectTypePOIStatusId ? (
                                                <div className="text-danger">
                                                    {formik.errors.projectTypePOIStatusId}
                                                </div>
                                            ) : null}
                                        </FormControl>

                                        <FormControl className="col-lg-12">
                                            <TextField
                                                error={getErrorField(formik, "title")}
                                                id="outlined-name"
                                                label="Title"
                                                margin="normal"
                                                variant="outlined"
                                                {...formik.getFieldProps("title")}
                                            />

                                            {formik.touched.title && formik.errors.title ? (
                                                <div className="text-danger">
                                                    {formik.errors.title}
                                                </div>
                                            ) : null}
                                        </FormControl>


                                        <FormControl className="col-lg-12">
                                            <TextField
                                                id="outlined-multiline-flexible"
                                                label="Description"
                                                multiline
                                                rowsMax="4"
                                                margin="normal"
                                                variant="outlined"
                                                className="large-textarea"
                                                {...formik.getFieldProps("description")}
                                            />
                                        </FormControl>

                                        {
                                            selectedPoint &&
                                            <>
                                                <p>Stem Length</p>
                                                <Slider
                                                    // defaultValue={slider}
                                                    value={slider}
                                                    onChangeCommitted={(event, value) => {
                                                        setSlider(value);
                                                        useTransientTagEditor ?
                                                            removeAndChangeStemLengthTransientEditor(value)
                                                            :
                                                            removeAndChangeStemLength(value);
                                                    }}
                                                    aria-labelledby="discrete-slider"
                                                    valueLabelDisplay="auto"
                                                    min={0}
                                                    step={0.000001}
                                                    max={3}
                                                />
                                            </>
                                        }
                                        <FormControl variant="outlined" className={`col-lg-12 ${classes.formControl}`}>
                                            {/* <div className="selected-chips-container">
                                                {customFieldsData.map((field) => (
                                                    <Chip
                                                        key={field.id}
                                                        label={`${field.name}: ${field.description}`}
                                                        onClick={() => handleEditCustomField(field)}
                                                        onDelete={() => handleDeleteCustomField(field.id)}
                                                        className="selected-chip"
                                                    />
                                                ))}
                                            </div> */}


                                            <div>
                                                <ExpansionPanel>
                                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                        <Typography variant="h6">Custom Fields</Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <div className="col-lg-12">
                                                            {filteredProjectPinCustomFields && filteredProjectPinCustomFields.length > 0 && filteredProjectPinCustomFields.map((field, index) => {
                                                                const validationError = validateDescription(field.description, field.customFieldType, field.options);
                                                                let inputComponent;

                                                                switch (field.customFieldType.toLowerCase()) {
                                                                    case 'dropdown':
                                                                        inputComponent = (
                                                                            <Select
                                                                                value={field.description || ''}
                                                                                onChange={(e) => handleDescriptionChange(e, index)}
                                                                                displayEmpty
                                                                                variant="outlined"
                                                                                margin="normal"
                                                                            >
                                                                                {field.customFieldTypeOptions.split(',').map((option, idx) => (
                                                                                    <MenuItem key={idx} value={option.trim()}>
                                                                                        {option}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        );
                                                                        break;
                                                                    case 'checkbox':
                                                                        inputComponent = field.customFieldTypeOptions.split(',').map((option, idx) => (
                                                                            <div className="col-lg-12 d-flex justify-content-between flex-column pt-4">
                                                                                <FormControlLabel
                                                                                    key={idx}
                                                                                    control={
                                                                                        <Checkbox
                                                                                            checked={field.description?.split(', ').includes(option.trim()) || false}
                                                                                            onChange={(e) => handleCheckboxChange(e, index, option.trim())}
                                                                                            name={field.name}
                                                                                        />
                                                                                    }
                                                                                    label={option.trim()}
                                                                                />
                                                                            </div>
                                                                        ));
                                                                        break;
                                                                    case 'multiselect':
                                                                        inputComponent = (
                                                                            <Select
                                                                                multiple
                                                                                value={field.description ? field.description.split(', ') : []}
                                                                                onChange={(e) => handleDescriptionChange(e, index)}
                                                                                renderValue={(selected) => selected.join(', ')}
                                                                                variant="outlined"
                                                                                margin="normal"
                                                                            >
                                                                                {field.customFieldTypeOptions.split(',').map((option, idx) => (
                                                                                    <MenuItem key={idx} value={option.trim()}>
                                                                                        {option}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>

                                                                        );
                                                                        break;
                                                                    default:
                                                                        inputComponent = (
                                                                            <TextField
                                                                                label="Description"
                                                                                value={field.description || ''}
                                                                                onChange={(e) => handleDescriptionChange(e, index)}
                                                                                multiline={field.customFieldType.toLowerCase().includes('text')}
                                                                                rowsMax={field.customFieldType.toLowerCase().includes('text') ? "4" : "1"}
                                                                                margin="normal"
                                                                                variant="outlined"
                                                                                className="large-textarea"
                                                                                InputLabelProps={field.customFieldType.toLowerCase().includes('date') ? { shrink: true } : undefined}
                                                                                type={field.customFieldType.toLowerCase().includes('date') ? 'date' : 'text'}
                                                                            />
                                                                        );
                                                                }

                                                                return (
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <FormControl variant="outlined" style={{ width: '100%', marginTop: 15 }}>
                                                                                <h5>{field.name}</h5>
                                                                                {field.customFieldType && <h6>({field.customFieldType})</h6>}
                                                                                {inputComponent}
                                                                                {validationError && <div className="text-danger">{validationError}</div>}
                                                                            </FormControl>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>

                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                            </div>
                                            {/* <Select
                                                labelId="demo-multiple-name-label"
                                                id="demo-multiple-name"
                                                multiple
                                                value={selectedCustomFieldIds}
                                                onChange={handleSelectChange}
                                                renderValue={selected => selected.map(value => projectTypeCustomFieldList.find(item => item.id === value)?.name).join(', ')}
                                            >
                                                {projectTypeCustomFieldList?.filter(field => !selectedCustomFieldIds.includes(field.id)).map((field) => (
                                                    <MenuItem key={field.id} value={field.id}>
                                                        {field.name}
                                                    </MenuItem>
                                                ))}
                                            </Select> */}
                                        </FormControl>

                                        <FormControl className="col-lg-12 d-flex justify-content-between flex-column pt-4">
                                            <div className="checkbox-container">
                                                {
                                                    projectPinCheckBoxes.map((curr, index) =>
                                                        <div key={index} className="checkbox-block">
                                                            <FormControlLabel
                                                                control={
                                                                    <RedCheckbox
                                                                        checked={curr.isChecked}
                                                                        onChange={(e, val) => {
                                                                            let tempArr = [...projectPinCheckBoxes];
                                                                            tempArr.find(x => x.name === curr.name).isChecked = val;

                                                                            setProjectPinCheckBoxes(tempArr);
                                                                        }}
                                                                    />
                                                                }
                                                                label={curr.name}
                                                                labelPlacement="end"
                                                            />
                                                        </div>
                                                    )
                                                }
                                            </div>

                                            <div className="save-cancel-button-container">
                                                <button
                                                    type="button"
                                                    onClick={removeSelectedMatterTag}
                                                    className="btn btn-danger btn-fixed-height font-weight-bold px-2 px-lg-5 mr-3"
                                                >Cancel</button>
                                                <button
                                                    type="submit"
                                                    disabled={formik.isSubmitting || (formik.touched && !formik.isValid) || hasCustomFieldErrors()}
                                                    className="btn btn-success btn-fixed-height font-weight-bold px-2 px-lg-5"
                                                >Save Point</button>
                                            </div>
                                        </FormControl>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        !isTableLoading &&
                        (auth.role === "SuperAdmin" || auth.role === "Admin" || auth.role === 'Manager') &&
                        (<div className="col-xl-12 mt-5">
                            <div className="matterport-project-actions d-flex justify-content-between mt-3">
                                <a
                                    onClick={() => showConfirmModal({
                                        title: "Complete Nspection",
                                        text: "Are you sure you want to complete this project?",
                                        rightBtnText: "Confirm",
                                        btnAction: () => markProjectAsComplete()
                                    })}
                                    className="btn btn-block btn-secondary btn-hover-success text-center py-10 px-5 mt-0 mr-3 pin-action-btn"
                                    style={{
                                        backgroundColor: '#f8f9fa',
                                        border: '1px solid #e2e6ea',
                                        color: '#495057',
                                    }}
                                >
                                    <span className="svg-icon svg-icon-3x svg-icon-success m-0">
                                        <SVG
                                            src={toAbsoluteUrl(
                                                "/media/svg/icons/Files/File-Done.svg"
                                            )}
                                        />
                                        <span className="d-block font-weight-bold font-size-h6 mt-2">Mark Inspection Complete</span>
                                    </span>

                                </a>
                                <Link
                                    to={{
                                        pathname: "/project/report",
                                        search: "?id=" + historyProjectId,
                                    }}
                                    className="btn btn-block btn-secondary btn-hover-info text-center py-10 px-5 mt-0 ml-3"
                                    style={{
                                        backgroundColor: '#f8f9fa',
                                        border: '1px solid #e2e6ea',
                                        color: '#495057',
                                    }}
                                >
                                    <span className="svg-icon svg-icon-3x svg-icon-info m-0">
                                        <SVG
                                            src={toAbsoluteUrl(
                                                "/media/svg/icons/Files/Selected-file.svg"
                                            )}
                                        />
                                    </span>
                                    <span className="d-block font-weight-bold font-size-h6 mt-2">Generate<br /> New Report</span>
                                </Link>
                            </div>
                        </div>)
                    }
                </div>
                <div className="col-xl-12 mt-5">
                    <div className="card card-custom">
                        <div className="card-body">
                            {isTableLoading ? (
                                <LinearProgress />
                            ) : (
                                <Tabs
                                    activeKey={activeTab}
                                    onSelect={(k) => setActiveTab(k)}
                                    className="mb-3"
                                >
                                    <Tab eventKey="inspectionPoints" title="Nspection Points">
                                        {renderInspectionPointsTab()}
                                    </Tab>
                                    <Tab eventKey="documents" title="Documents">
                                        {renderDocumentsTab()}
                                    </Tab>
                                    <Tab eventKey="inspectionData" title="Nspection Properties">
                                        {renderInspectionPropertiesTab()}
                                    </Tab>
                                </Tabs>
                            )}
                        </div>
                    </div>
                </div>
                <UpdateProjectPinModal
                    tierPlan={tier}
                    poiStatus={poiStatusName}
                    projectPinId={editProjectPinId}
                    user={auth.user}
                    project={project}
                    imageString={imageString}
                    show={updateProjectPinModalVisible}
                    hide={() => setUpdateProjectPinModalVisible(false)}
                    reloadTable={() => {
                        setIsTableLoading(true);
                        if (project3d) {
                            fetchProjectPinsForAProject(project);
                        }
                        else {
                            setFetchNewAnnotation(!fetchNewAnnotation);
                        }
                    }}
                />
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps, { ...alert.actions, ...confirmModal.actions })(ProjectEditPage);
/* eslint-disable no-restricted-imports */
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import * as poiStatusService from "../../../services/settings/POIStatusService";
import * as clientTierService from '../../../services/management/clientTierService';
import { Button, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import POIStatusValidator from "../../../helpers/validators/PointCategoryValidator";
import { getErrorField, checkTierPlanAndRoleTier3 } from '../../../helpers/helper';
import * as alert from "../../../redux/alertSnackBarRedux";
import * as confirmModal from "../../../redux/confirmModalRedux";
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import Checkbox from '@material-ui/core/Checkbox';
import { SketchPicker } from 'react-color';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ProjectTypeNavbar from '../../components/ProjectTypeNavbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';


const BlueCheckBox = withStyles({
    root: {
        color: blue[400],
        '&$checked': {
            color: blue[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

var initialPOIStatus = {
    id: 0,
    name: '',
    description: '',
    projectTypeId: '',
    iconPath: '',
    poiColorCode: '',
    isDeleted: false
}

const ProjectTypePOIStatusListingPage = (props) => {
    const location = useLocation();
    const { id } = useParams();
    const { projectTypeId, projectType } = location.state || {};
    const { showAlert, showConfirmModal, hideConfirmModal, history, auth } = props;
    const [poiColor, setPoiColor] = useState('');
    const [isBusinessTier, setIsBusinessTier] = useState(false);
    const [tier, setTier] = useState('');
    const [data, setData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPOIStatus, setSelectedPOIStatus] = useState(initialPOIStatus);

    useEffect(() => {
        loadTableData();
    }, [loadTableData])

    const loadTableData = useCallback(() => {

        if (id) {
            poiStatusService.getPOIStatusByProjectTypeId(id).then(res => {
                setData(res);

                return res[0];
            }).then(projectType => {
                if (projectType) {
                    fetchClientTier(projectType);
                }

            })
                .catch((ex) => {
                    showAlert({ message: ex.message, variant: "error" });
                });
        }
        else {
            poiStatusService.getPOIStatuses().then(res => {
                setData(res);
            }).catch((ex) => {
                showAlert({ message: ex.message, variant: "error" });
            });
        }
    })

    const fetchClientTier = (poi) => {
        let tierId = poi.projectType.client.clientTier.id;
        let isTier3 = false;
        if (tierId) {
            clientTierService.getClientTierById(tierId).then(res => {
                setTier(res);
                return res;
            }).then(tier => {
                isTier3 = checkTierPlanAndRoleTier3(tier, auth.role);
                if (isTier3) {
                    setIsBusinessTier(isTier3);
                }
            });
        }
    }

    const editPOIStatus = (id) => {
        poiStatusService.getPOIStatusById(id).then(res => {
            setSelectedPOIStatus(res);
            setIsModalOpen(true);
            setPoiColor('');
        })
    }

    // const deletePointCategory = (id) => {
    //     pointCategoryService.deletePointCategory(id).then(res => {
    //         showAlert({ message: res.message, variant: "success" });
    //         loadTableData();
    //         hideConfirmModal();
    //     })
    // }

    const deletePOIStatus = (id, projectTypeId) => {
        let deleteModel = { ...selectedPOIStatus, isDeleted: true, projectTypeId: projectTypeId, id: id }
        poiStatusService.deletePOIStatusIsDeletedFlag(deleteModel).then(res => {
            showAlert({ message: res.message, variant: "success" });
            loadTableData();
            hideConfirmModal();
        })
    }

    const columns = [
        {
            name: "name",
            label: "Name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "description",
            label: "Description",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            {data[dataIndex].description ? data[dataIndex].description : "-"}
                        </>
                    );
                }
            }
        },
        ...(isBusinessTier ?
            [{
                name: "poiColorCode",
                label: "poiColorCode",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRenderLite: (dataIndex) => {
                        return (
                            <>
                                {data[dataIndex].poiColorCode ? data[dataIndex].poiColorCode : "-"}
                            </>
                        );
                    }
                }
            }] : []),
        {
            name: "isDefault",
            label: "Is Default",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    return data[dataIndex].isDefault &&
                        <span className="label label-lg c-white bg-success label-inline font-weight-bold py-4 mr-3 mt-2 mb-2" style={{
                            whiteSpace: 'nowrap'
                        }}>
                            {'Default'}
                        </span>
                }

            }
        },
        {
            name: "id",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                width: 1,
                download: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            <button
                                className="btn btn-sm btn-info font-weight-bold py-2 px-3 px-xxl-5 my-1"
                                onClick={() => editPOIStatus(data[dataIndex].id)}
                            >
                                Edit
                            </button> &nbsp;
                            <button
                                onClick={() => showConfirmModal({
                                    title: "Delete N-Point Category",
                                    text: "Are you sure you want to delete this n-point category?",
                                    rightBtnText: "Confirm",
                                    btnAction: () => deletePOIStatus(data[dataIndex].id, data[dataIndex].projectTypeId)
                                })}
                                className="btn btn-sm btn-danger font-weight-bold py-2 px-3 px-xxl-5 my-1"
                            >
                                Delete
                            </button>
                        </>
                    );
                }
            },

        },
    ];

    const handleModalClose = () => {
        setIsModalOpen(false);
        setSelectedPOIStatus(initialPOIStatus);
        formik.resetForm();
    }

    const handleColorChange = (color) => {
        setPoiColor(color.hex);
        formik.setFieldValue("poiColorCode", color.hex);
    }

    const savePOIStatus = (values, setStatus, setSubmitting) => {

        let newValues = { ...values, projectTypeId: id, poiColor: poiColor };
        poiStatusService.savePOIStatus(newValues).then(res => {
            showAlert({ message: res.message, variant: "success" });
            loadTableData();
            handleModalClose();
        }).catch((ex) => {
            showAlert({ message: ex.message, variant: "error" });
        })
    }


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: selectedPOIStatus,
        validationSchema: POIStatusValidator,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            savePOIStatus(values, setStatus, setSubmitting);
        }
    });

    return (
        <div className="row" >
            <Modal centered size="md" show={isModalOpen} onHide={handleModalClose}>
                <form className="card card-custom" onSubmit={formik.handleSubmit}>
                    <Modal.Header closeButton>
                        Nspection Type Name: {projectType?.name}
                        <br />
                        <Modal.Title>N-Point Status</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form">
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <TextField
                                        error={getErrorField(formik, "name")}
                                        id="outlined-name"
                                        label="Name"
                                        margin="normal"
                                        variant="outlined"
                                        {...formik.getFieldProps("name")}
                                    />
                                    {formik.touched.name && formik.errors.name ? (
                                        <div className="text-danger">
                                            {formik.errors.name}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <TextField
                                        error={getErrorField(formik, "description")}
                                        id="outlined-name"
                                        label="Description"
                                        multiline
                                        margin="normal"
                                        variant="outlined"
                                        {...formik.getFieldProps("description")}

                                    />
                                    {formik.touched.description && formik.errors.description ? (
                                        <div className="text-danger">
                                            {formik.errors.description}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            {isBusinessTier &&
                                <>
                                    <div className="form-group row">
                                        <div className="col-lg-12">
                                            <TextField
                                                error={getErrorField(formik, "poiColorCode")}
                                                id="outlined-name"
                                                InputLabelProps={{ shrink: true }}
                                                label="Color Code"
                                                {...formik.getFieldProps("poiColorCode")}
                                                value={poiColor ? poiColor : formik.values.poiColorCode}
                                                margin="normal"
                                                variant="outlined"
                                                style={{ backgroundColor: poiColor ? poiColor : formik.values.poiColorCode }}
                                            />
                                            {formik.touched.poiColorCode && formik.errors.poiColorCode ? (
                                                <div className="text-danger">
                                                    {formik.errors.poiColorCode}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-lg-12">
                                            <SketchPicker
                                                width='400'
                                                color={poiColor}
                                                onChangeComplete={handleColorChange}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                            <div className="col-lg-6">
                                <FormControl className="col-lg-12 d-flex justify-content-between flex-column pt-4">
                                    <div className="checkbox-container">
                                        {
                                            <FormControlLabel
                                                control={
                                                    <BlueCheckBox
                                                        checked={formik.values.isDefault}
                                                        onChange={(e, val) => {
                                                            formik.setFieldValue("isDefault", val);
                                                        }}
                                                    />
                                                }
                                                label="Is Default"
                                                labelPlacement="end"
                                            />
                                        }
                                    </div>
                                </FormControl>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                            Close
                        </Button>
                        &nbsp;
                        <button
                            type="submit"
                            className="btn btn-success mr-2"
                            disabled={
                                formik.isSubmitting || (formik.touched && !formik.isValid)
                            }
                        >
                            Save
                            {formik.isSubmitting}
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
            <div className="col-lg-12 col-xxl-12">
                <div className={`card card-custom card-stretch gutter-b}`}>
                    <div className="card-header border-0 py-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label font-weight-bolder text-dark">
                                {projectType?.name}
                            </span>
                            <span className="card-label font-weight-bolder text-dark">
                                N-Point Status
                            </span>
                        </h3>
                        <div className="card-toolbar">
                            <button
                                onClick={() => setIsModalOpen(true)}
                                className="btn btn-success font-weight-bolder font-size-sm mr-3"
                            >
                                <span className="fa fa-plus"></span> Add POI Status
                            </button>
                        </div>
                    </div>
                    <ProjectTypeNavbar id={id} projectType={projectType} />
                    <div className="container-fluid">
                        <MUIDataTable
                            data={data}
                            columns={columns}
                            options={{
                                selectableRows: 'none'
                            }}
                        />
                    </div>
                </div>
            </div>
        </div >
    )
}

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps, { ...alert.actions, ...confirmModal.actions })(ProjectTypePOIStatusListingPage);
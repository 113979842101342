import React, { useState, useEffect } from 'react';
import AddClientModal from '../../../components/AddClientModal';
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import * as alert from "../../../../redux/alertSnackBarRedux";
import * as confirmModal from "../../../../redux/confirmModalRedux";

import * as clientService from "../../../../services/management/clientService";
import * as clientTierService from "../../../../services/management/clientTierService";

import constants from '../../../../constants/constants';

const ClientListingPage = (props) => {
    const { showAlert, showConfirmModal, hideConfirmModal, auth, history, setProcessing } = props;
    const [loading, setLoading] = useState(false);
    const [paymentProfileLinks, setPaymentProfileLinks] = useState({});
    const [deleteClientModel, setDeleteClientModel] = useState(
        {
            id: 0,
            logoPath: null,
            businessName: '',
            contactName: '',
            contactEmail: '',
            contactPhone: '',
            companyAddress: '',
            isDeleted: false
        }
    )
    const [clientModalVisible, setClientModalVisible] = useState(false)
    const [clients, setClients] = useState([]);
    const [clientToEditId, setClientToEditId] = useState(null);

    useEffect(() => {
        loadClientTable();
    }, [])

    const loadClientTable = () => {
        clientService.getClients().then(res => {
            setClients(res);
        })
    }

    const loadPaymentProfileLink = (id) => {
        const existingLinkInfo = paymentProfileLinks[id];

        if (existingLinkInfo) {
            const expiresAt = new Date(existingLinkInfo.expiresAt);
            const now = new Date();

            if (expiresAt > now) {
                window.open(existingLinkInfo.url, '_blank'); // Open URL in a new tab
                return;
            }
        }

        clientService.getManagementLink(id).then(res => {
            if (res && res.url && res.expiresAt) {
                const expiresAt = new Date(res.expiresAt);
                const now = new Date();

                if (expiresAt > now) {
                    setPaymentProfileLinks({
                        ...paymentProfileLinks,
                        [id]: { url: res.url, expiresAt: res.expiresAt } // Store the URL and its expiry time
                    });
                    window.open(res.url, '_blank'); // Open URL in a new tab
                } else {
                    showAlert({ message: "The link has expired.", variant: 'error' });
                }
            } else {
                showAlert({ message: "Failed to get a valid URL or expiry date.", variant: 'error' });
            }
        }).catch(ex => {
            showAlert({ message: ex.message, variant: 'error' });
        })
    }

    // const loadClientTierLists = () => {
    //     clientTierService.getClientTiers().then(res => {
    //         setClientTierList(res);
    //     })
    // }

    const deleteClient = (clientId) => {
        setLoading(true);
        setProcessing(true)
        let newModel = { ...deleteClientModel, isDeleted: true, id: clientId };
        clientService.deleteClientByIsDeleteFlag(newModel).then(res => {
            showAlert({ message: res.message, variant: 'success' });
            loadClientTable();
            hideConfirmModal();
        }).catch(ex => {
            showAlert({ message: ex.message, variant: 'error' })
        }).finally(() => {
            setLoading(false);
            setProcessing(false)
        })
    }

    const columns = [
        {
            name: "businessName",
            label: "Business Name",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <div className="tc-user-block">
                            <div
                                className="img-block background-image-container"
                                style={{ backgroundImage: `url(${clients[dataIndex].logoPath ? (constants.API_URL + 'api/' + clients[dataIndex].logoPath) : ("")})` }}
                            >
                            </div>

                            <div className="user-info-block">
                                <a href="#x" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                    {clients[dataIndex].businessName}
                                </a>
                            </div>
                        </div>
                    )
                }
            },
        },
        {
            name: "contactName",
            label: "Contact Name",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
            },
        }, {
            name: "contactPhone",
            label: "Contact Phone",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
            },
        }, {
            name: "contactEmail",
            label: "Contact Email",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
            },
        },
        {
            name: "noOfProject",
            label: "No of Nspections",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
            },
        },
        {
            name: "noOfReport",
            label: "No of Reports",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <p>{clients[dataIndex].noOfReport ? clients[dataIndex].noOfReport : 'Default'}</p>
                    )
                }
            },
        },
        {
            name: "clientTier",
            label: "Tier Plan",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <p>{clients[dataIndex].clientTier}</p>
                    )
                }
            },
        },
        {
            name: "id",
            label: "Actions",
            options: {
                filter: false,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <div className='d-flex'>
                            <button
                                className="btn btn-info font-weight-bolder font-size-sm mr-3"
                                disabled={auth.role !== "SuperAdmin" && auth.role !== 'Admin'}
                                onClick={() => {
                                    setClientToEditId(clients[dataIndex].id);
                                    setClientModalVisible(true);
                                }}
                            >
                                Edit
                            </button>
                            <button
                                className="btn btn-info font-weight-bolder font-size-sm mr-3"
                                disabled={auth.role !== "SuperAdmin" && auth.role !== 'Admin'}
                                // disabled={(auth.role !== "SuperAdmin" || auth.role !== "Admin")}
                                onClick={() => {
                                    loadPaymentProfileLink(clients[dataIndex].refId);
                                }}
                            >
                                Billing
                            </button>
                            {
                                auth.role === "SuperAdmin" ?
                                    <button
                                        className="btn btn-danger btn-hover-danger font-size-sm mr-3"
                                        disabled={auth.role !== "SuperAdmin" && loading}
                                        onClick={() => showConfirmModal({
                                            title: "Delete Client",
                                            text: "Are you sure you want to delete this client? Note that deleting the client will delete the subscriptions associated with the client which cannot be recovered.",
                                            rightBtnText: `Confirm`,
                                            btnAction: () => deleteClient(clients[dataIndex].id),
                                        })
                                        }
                                    >
                                        Delete
                                    </button>
                                    : <></>
                            }
                        </div>
                    );
                }
            }
        }
    ];

    return (
        <div className="row">
            <div className="col-lg-12 col-xxl-12">
                <div className={`card card-custom card-stretch gutter-b}`}>
                    <div className="card-header border-0 py-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label font-weight-bolder text-dark">
                                Clients
                            </span>
                            <span className="text-muted mt-3 font-weight-bold fz-xs">
                                View and Manage Clients
                            </span>
                        </h3>
                        <div className="card-toolbar">
                            {
                                auth.role === 'SuperAdmin' ?
                                    <>
                                        <button
                                            onClick={() => {
                                                setClientToEditId(null);
                                                setClientModalVisible(true);
                                            }}
                                            className="btn btn-success font-weight-bolder font-size-sm mr-3"
                                        >
                                            <span className="fa fa-plus"></span> Add Client
                                        </button>
                                    </>
                                    :
                                    <></>
                            }

                        </div>
                    </div>

                    <div className="container-fluid">
                        <MUIDataTable
                            data={clients}
                            columns={columns}
                            options={{
                                selectableRows: 'none',
                            }}
                        />
                        <AddClientModal
                            clientToEditId={clientToEditId}
                            reloadTable={() => loadClientTable()}
                            resetClientToEdit={() => setClientToEditId(null)}
                            show={clientModalVisible}
                            hide={() => setClientModalVisible(false)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps, { ...alert.actions, ...confirmModal.actions })(ClientListingPage);
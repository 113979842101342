import React, { useEffect, useState } from "react";
import AddUserModal from '../../../components/AddUserModal';
import MUIDataTable from "mui-datatables";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import * as userService from "../../../../services/management/userService";
import { connect } from "react-redux";
import * as alert from "../../../../redux/alertSnackBarRedux";
import { checkTierPlanAndRoleFreeTier, F } from '../../../../helpers/helper';
import * as confirmModal from "../../../../redux/confirmModalRedux";
import ChangePasswordModal from "../../../components/ChangePasswordModal";
import AssignProjectModal from "../../../components/AssignProjectModal";

const UserListingPage = (props) => {
    const { showAlert, showConfirmModal, hideConfirmModal, tierPlan, auth } = props;
    const [deleteUserModel, setDeleteUserModel] = useState(
        {
            id: 0,
            firstName: '',
            lastName: '',
            phoneNumber: '',
            clientId: 0,
            username: '',
            password: '',
            role: '',
            roleId: '',
            isDeleted: false
        }
    )
    const [userModalVisible, setUserModalVisible] = useState(false);
    const [assignProjectModalVisible, setAssignProjectModalVisible] = useState(false);
    const [users, setUsers] = useState([]);
    const [userToEditId, setUserToEditId] = useState(null);
    const [changePasswordModalIsVisible, setChangePasswordModalIsVisible] = useState(false);

    useEffect(() => {
        loadUsersTable();
    }, []);

    const loadUsersTable = () => {
        userService.getAllUsers().then(res => {
            setUsers(res);
        })
    }

    const columns = [
        {
            name: "firstName",
            label: "First Name",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
            },
        },
        {
            name: "lastName",
            label: "Last Name",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
            },
        },
        {
            name: "phoneNumber",
            label: "Phone Number",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
            },
        },
        {
            name: "username",
            label: "Username",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
            },
        }, {
            name: "client",
            label: "Client",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <p>{users[dataIndex].client ? users[dataIndex].client : '-'}</p>
                    )
                }
            },
        },
        {
            name: "role",
            label: "Role",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <p>{users[dataIndex].role}</p>
                    )
                }
            },
        },

        // {
        //     name: "isAdmin",
        //     label: "Is Admin?",
        //     options: {
        //         filter: true,
        //         sort: true,
        //         customBodyRenderLite: (dataIndex) => {
        //             return (
        //                 <SVG
        //                     className="h-50 align-self-center"
        //                     src={toAbsoluteUrl(users[dataIndex].role =='SuperAdmin' ? "/media/svg/icons/Navigation/Check.svg" : "/media/svg/icons/Navigation/Close.svg")}
        //                 >
        //                 </SVG>
        //             )
        //         }
        //     }
        // },
        {
            name: "id",
            label: "Actions",
            options: {
                filter: false,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            {/* <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="toggle-quick-actions">Edit User</Tooltip>
                                }
                            >
                                <a
                                    className="btn btn-icon btn-light btn-hover-info btn-sm mx-3"
                                    onClick={() => {
                                        setUserToEditId(users[dataIndex].id);
                                        setUserModalVisible(true);
                                    }}
                                >
                                    <span className="svg-icon svg-icon-md svg-icon-info">
                                        <SVG
                                            src={toAbsoluteUrl("/media/svg/icons/General/Edit.svg")}
                                        />
                                    </span>
                                </a>
                            </OverlayTrigger> */}
                            <button
                                disabled={users[dataIndex].role === "SuperAdmin" || users[dataIndex].role === "Admin" || users[dataIndex].role === 'Manager'}
                                className="btn btn-warning btn-sm mr-3"
                                onClick={() => {
                                    setUserToEditId(users[dataIndex].id);
                                    setAssignProjectModalVisible(true);
                                }}
                            >
                                Assign 
                            </button>
                            <button
                                className="btn btn-info btn-sm mr-3"
                                onClick={() => {
                                    setUserToEditId(users[dataIndex].id);
                                    setUserModalVisible(true);
                                }}
                            >
                                Edit
                            </button>

                            {/* <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="toggle-quick-actions">Change Password</Tooltip>
                                }
                            >
                                <a
                                    className="btn btn-icon btn-light btn-hover-warning btn-sm mr-3"
                                    onClick={() => {
                                        setUserToEditId(users[dataIndex].id);
                                        setChangePasswordModalIsVisible(true);
                                    }}
                                >
                                    <span className="svg-icon svg-icon-md svg-icon-warning">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Shield-user.svg")} />
                                    </span>
                                </a>
                            </OverlayTrigger> */}

                            {/* <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="toggle-quick-actions">Delete User</Tooltip>
                                }
                            >
                                <a
                                    className="btn btn-icon btn-light btn-hover-danger btn-sm mr-3"
                                    onClick={() => showConfirmModal({
                                        title: "Delete User",
                                        text: "Are you sure you want to delete this user?",
                                        rightBtnText: "Confirm",
                                        btnAction: () => deleteUser(users[dataIndex].id)
                                    })
                                    }
                                >
                                    <span className="svg-icon svg-icon-md svg-icon-danger">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                                    </span>
                                </a>
                            </OverlayTrigger> */}
                         {
                            checkTierPlanAndRoleFreeTier(tierPlan, auth.role) && 
                            <button
                            className="btn btn-danger btn-sm mr-3"
                            onClick={() => showConfirmModal({
                                title: "Delete User",
                                text: "Are you sure you want to delete this user?",
                                rightBtnText: "Confirm",
                                btnAction: () => deleteUser(users[dataIndex].id, users[dataIndex].clientId, users[dataIndex].roleId)
                            })
                            }
                        >
                            Delete
                        </button>
                         }

                            <button
                                className="btn btn-success btn-sm mr-3"
                                onClick={() => {
                                    setUserToEditId(users[dataIndex].id);
                                    setChangePasswordModalIsVisible(true);
                                }}
                            >
                                Update Password
                            </button>
                        </>
                    );
                }
            }
        }
    ];

    const deleteUser = (userId, clientId, roleId) => {
        let newModel = { ...deleteUserModel, isDeleted: true, id: userId, roleId: roleId, clientId: clientId };
        userService.deleteUserByIsDelete(newModel).then(res => {
            showAlert({ message: res.message, variant: 'success' });
            loadUsersTable();
        }).catch(ex => {
            showAlert({ message: ex.message, variant: 'error' })
        }).finally(() => hideConfirmModal())
    }

    return (
        <div className="row">
            <div className="col-lg-12 col-xxl-12">
                <div className={`card card-custom card-stretch gutter-b}`}>
                    <div className="card-header border-0 py-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label font-weight-bolder text-dark">
                                Users
                            </span>
                            <span className="text-muted mt-3 font-weight-bold fz-xs">
                                View and Manage Users
                            </span>
                        </h3>
                        {
                            (auth.role === 'SuperAdmin' || auth.role === 'Admin') &&
                            <div className="card-toolbar">
                            <button
                                onClick={() => {
                                    setUserToEditId(null);
                                    setUserModalVisible(true)
                                }}
                                className="btn btn-success font-weight-bolder font-size-sm mr-3"
                            >
                                <span className="fa fa-plus"></span> Add User
                            </button>
                        </div>
                        }
                    </div>
                    <div className="container-fluid">
                        <MUIDataTable
                            data={users}
                            columns={columns}
                            options={{
                                selectableRows: 'none'
                            }}
                        />
                        <AddUserModal
                            userToEditId={userToEditId}
                            reloadTable={() => loadUsersTable()}
                            show={userModalVisible}
                            resetUserToEdit={() => setUserToEditId(null)}
                            hide={() => setUserModalVisible(false)}
                        />
                        <AssignProjectModal
                            userToEditId={userToEditId}
                            reloadTable={() => loadUsersTable()}
                            show={assignProjectModalVisible}
                            hide={() => setAssignProjectModalVisible(false)}
                        />
                        <ChangePasswordModal
                            userToEditId={userToEditId}
                            show={changePasswordModalIsVisible}
                            resetUserToEdit={() => setUserToEditId(null)}
                            hide={() => setChangePasswordModalIsVisible(false)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    auth: state.auth
})
export default connect(mapStateToProps, { ...alert.actions, ...confirmModal.actions })(UserListingPage);